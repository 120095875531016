import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-commonheader',
  templateUrl: './commonheader.component.html',
  styleUrls: ['./commonheader.component.scss']
})
export class CommonheaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  

}

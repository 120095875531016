import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ApilistService } from '../services/api/apilist.service';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  isPlay: boolean = false;
  language: any;
  selected: string;
  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }
  playPause() {
    var myVideo: any = document.getElementById("my_video_1");
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
  }

  makeBig() {
    var myVideo: any = document.getElementById("my_video_1");
    myVideo.width = 560;
  }

  makeSmall() {
    var myVideo: any = document.getElementById("my_video_1");
    myVideo.width = 320;
  }

  makeNormal() {
    var myVideo: any = document.getElementById("my_video_1");
    myVideo.width = 420;
  }

  skip(value) {
    let video: any = document.getElementById("my_video_1");
    video.currentTime += value;
  }

  restart() {
    let video: any = document.getElementById("my_video_1");
    video.currentTime = 0;
  }
  constructor(private http: HttpClient, private fb: FormBuilder, public apiList: ApilistService, private authenticationService: AuthenticationService, private router: Router,private translateService: TranslateService) {
    setTimeout(()=>{
      this.selected = localStorage.getItem('selectLanguage')!='' ? localStorage.getItem('selectLanguage')  : 'en'
    },100)

  }

  ngOnInit(): void {

    console.log(this.selected)

if(localStorage.getItem("loginStatus")=="true"){
  if(localStorage.getItem("userType")=="company"){
    this.router.navigate(['company/homepagecompany']);
  }
  else if(localStorage.getItem("userType")=="customer"){
    this.router.navigate(['customers/homepagecustomer']);
  }
  else{
    
  }
  }

  this. translateService.setDefaultLang('en');
  if(localStorage.getItem('selectLanguage')){
    localStorage.setItem('selectLanguage', localStorage.getItem('selectLanguage'));
  }
  else{
    localStorage.setItem('selectLanguage','en');
 
  }
  // localStorage.setItem('selectLanguage','en');

 }

 switchLanguage(language) {
   console.log(language)
   this.language = language
  this.translateService.use(this.language);
  localStorage.setItem('selectLanguage',this.language);
}

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev:KeyboardEvent) {
    if(ev.code == "Space") {
      let vid = <HTMLVideoElement>document.getElementById("my_video_1");
      if(vid.paused) {
        vid.play();
      }
      else {
        vid.pause();
      }
    }
  }

}

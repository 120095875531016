import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApilistService } from './services/api/apilist.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { SocketService } from './socket/socket.service';
import { StarRatingComponent } from 'ng-starrating';
// import { MessagingService } from '../app/messaging.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../environments/environment';




declare var $: any;
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'hjelpsom-app';
  ringToneAudio: any;
  userActivity;
  userInactive: Subject<any> = new Subject();
  translatedLanguage: any;
  answerCallTranslation: any;
  translatedCallTitle: any;
  cancelCallTranslation: string;
  logoutInActivity: any;
  callDisconnectedTranslation: any;
  userType: any;

  constructor(private socketService: SocketService,private http: HttpClient,private router: Router, public apiList: ApilistService, private authenticationService: AuthenticationService, private translateService : TranslateService
    ) {

  
    localStorage.user !== undefined ?
    this.socketService.joinUser() : null;
    localStorage.user !== undefined ?
    this.socketService.setupSocketConnection() : null;
    this.setTimeout();
    this.userInactive.subscribe(() =>{
      this.logout();
    } 
    );

    
    // if ("serviceWorker" in navigator) {
    //   //  console.log('--------in service qorker condition')
    //     window.addEventListener("load",  () => {
    //       console.log("inside")
    //       navigator.serviceWorker.register("../firebase-messaging-sw.js").then(
    //          (registration) => {
    //           console.log(
    //             "ServiceWorker registration successful with scope: ",
    //             registration.scope
    //           );
    //         },
    //         (err) => {
    //           // registration failed :(
    //          // console.log("ServiceWorker registration failed: ", err);
    //         }
    //       );
    //     });
    //   }
    //  this.messagingService.requestPermission();
      this.socketService.userJoined.subscribe((message:any)=>{
        // alert();
        console.log(message,"message received---------#######")
        if(message.data){
        if(message.data.notificationType){


        if (message.data.notificationType === "joinvideocall") {
          this.playAudio();
          console.log(message.data, "data")
          if(this.translatedLanguage == 'nn'){
            this.translateService.get('Getting a call from').subscribe((res:any)=>{
               this.translateService.get('Answer').subscribe((answerCallResponse:any)=>{
                this.translateService.get('Cancel').subscribe((cancelCallResponse:any)=>{
                this.translatedCallTitle = res;
                this.answerCallTranslation = answerCallResponse;
                this.cancelCallTranslation = cancelCallResponse

                console.log(this.translatedCallTitle, this.answerCallTranslation, this.cancelCallTranslation)
               })  
  
            })
          })
          }
  
          else {
            this.translatedCallTitle = 'Getting a call from';
            this.answerCallTranslation = 'Answer';
            this.cancelCallTranslation = 'Cancel'

            console.log(this.translatedCallTitle, this.answerCallTranslation, this.cancelCallTranslation)


  
          }
          Swal.fire({
            title: this.translatedCallTitle +' ' + message.data.senderPhone,
            imageWidth: 200,
            imageHeight: 200,
            imageUrl: '../assets/images/callIcon.jpg',
            showCancelButton: true,
            cancelButtonText:this.cancelCallTranslation,
            confirmButtonColor: '#5ed4be;',
            cancelButtonColor: '#9d9d9d',
            confirmButtonText: this.answerCallTranslation,
            showCloseButton: true,
            allowOutsideClick: false
          }).then((result: any) => {
            if (result.value) {
              this.pauseAudio();
              var fd = new FormData
              fd.append('channelID', message.data.invitationId )
              this.authenticationService.connectAgora(message.data.invitationId ).subscribe((res: any) => {
                if (!res.isError) {
                  this.pauseAudio();
                  localStorage.setItem("rtcToken", res.token);
                  // this.common.hideSpinner();
                  this.router.navigate(["videoCall"], { queryParams: { 'id': message.data.invitationId ,'token':res.token,'callId' : message.data.callId}});
                  // this.router.navigate(['/video_call',message.data.receiverId,res.token])
  
                }
                else {
                  this.pauseAudio();
                  // this.common.hideSpinner();
                  // this.common.error(res.message)
                }
              }).add(() => { this.pauseAudio();  });
  
              // this.socketService.createCall(this.callObject.callTo, false, true, false, undefined);
              // this.pauseAudio();
              // window.open(sessionLink, '_blank');
            }
            if (result.dismiss === 'cancel') {
              this.pauseAudio();
               this.rejectCall(message.data.invitationId,message.data.callId);
            }
          })
        }
      }
    }
        // if(message.data.notificationType =='disconnectvideocall'){
        //   this.router.navigate(['/'])
        // }

})  

this.socketService.userCancelled.subscribe((message: any) => {
  console.log('user disconnect')
  if(message.data){
  if(message.data.notificationType){
  if(message.data.notificationType =='disconnectvideocall'){
    if(this.userType == 'company'){
      this.router.navigate(['company/messagecompany'])

     }

     else {
       console.log('hello')
      this.router.navigate(['customers/messagecustomer'])

     }
    
    // this.router.navigate(['/'])
  }
}
}


})


  

   }

   logout() {

    this.authenticationService.logoutcustomer().subscribe(res => {
      this.socketService.disconnectConnection();

      if(this.translatedLanguage == 'nn'){
        this.translateService.get('Logged out due to inactivity').subscribe((res:any)=>{
          this.logoutInActivity = res;
        
      })
      }

      else {
        this.logoutInActivity = 'Logged out due to inactivity';

      }
      this.authenticationService.openSnackBar(this.logoutInActivity, 'Done', '5000','blue-snackbar' ,'end','center'
);
      setTimeout(() => {
        this.router.navigate(['/splashscreen']);
        localStorage.clear();
        window.location.reload();
      }, 500)

    });
  }
  ngOnInit() {
    if(localStorage.getItem("user")){
        this.authenticationService.userData=JSON.parse(localStorage.getItem("user"));
       this.userType=localStorage.getItem("userType");

       if(this.userType=="company"){
        if(this.authenticationService.userData.logo){
          this.authenticationService.changeMemberPhoto(this.authenticationService.userData.logo);
          this.getCompanyLanguage();
        }
       }
       else{
        if(this.authenticationService.userData.profilePic){
          this.authenticationService.changeMemberPhoto(this.authenticationService.userData.profilePic);
          this.getUserLanguage();
          
        }
       }
        
  
      }

       // this.message.receiveMessage();
      this.socketService.userJoined.subscribe((message: any) => {
        // alert();

        console.log(message, "message received---------#######");
        if(message.data){
        if(message.data.notificationType){
        if (message.data.notificationType === "joinvideocall") {
          console.log(message.data, "data")
          this.playAudio();


          if(this.translatedLanguage == 'nn'){
            this.translateService.get('Getting a call from').subscribe((res:any)=>{
               this.translateService.get('Answer').subscribe((answerCallResponse:any)=>{
                this.translateService.get('Cancel').subscribe((cancelCallResponse:any)=>{
                this.translatedCallTitle = res;
                this.answerCallTranslation = answerCallResponse;
                this.cancelCallTranslation = cancelCallResponse
               })  
  
            })
          })
          }
  
          else {
            this.translatedCallTitle = 'Getting a call from';
            this.answerCallTranslation = 'Answer';
            this.cancelCallTranslation = 'Cancel'
  
  
          }


          Swal.fire({
            title: this.translatedCallTitle + ' ' + message.data.senderPhone,
            imageWidth: 200,
            imageHeight: 200,
            imageUrl: '../assets/images/callIcon.jpg',
            showCancelButton: true,
            cancelButtonText:this.cancelCallTranslation,
            confirmButtonColor: '#5ed4be;',
            cancelButtonColor: '#9d9d9d',
            confirmButtonText: this.answerCallTranslation,
            showCloseButton: false,
            allowOutsideClick: false
          }).then((result: any) => {
            if (result.value) {
              this.pauseAudio();
              var fd = new FormData
              fd.append('channelID', message.data.invitationId )
              this.authenticationService.connectAgora(message.data.invitationId ).subscribe((res: any) => {
                if (!res.isError) {
                  this.pauseAudio();
                  localStorage.setItem("rtcToken", res.token);
                  // this.common.hideSpinner();
                  this.router.navigate(["videoCall"], { queryParams: { 'id': message.data.invitationId ,'token':res.token}});
                  // this.router.navigate(['/video_call',message.data.receiverId,res.token])
  
                }
                else {
                  this.pauseAudio();
                  // this.common.hideSpinner();
                  // this.common.error(res.message)
                }
              }).add(() => { this.pauseAudio();  });
  
              // this.socketService.createCall(this.callObject.callTo, false, true, false, undefined);
              // this.pauseAudio();
              // window.open(sessionLink, '_blank');
            }
            if (result.dismiss === 'cancel') {
              this.pauseAudio();
               this.rejectCall(message.data.invitationId,message.data.callId);
            }
          })
        }
      }
    }
        // if(message.data.notificationType =='disconnectvideocall'){
        //   this.router.navigate(['/'])
        // }
     
      
      })


      this.socketService.userCancelled.subscribe((message: any) => {
        if(message.data){
        if(message.data.notificationType){
        if(message.data.notificationType =='disconnectvideocall'){
          if(this.userType == 'company'){
            this.router.navigate(['company/messagecompany'])
    
           }
    
           else {
            this.router.navigate(['customers/messagecustomer'])
    
           }
          // this.router.navigate(['/'])
        }
      }
    }
     
      
      })
    }
    


    rejectCall(invitationid,callId){

      var data={
        "invitationId" : invitationid,
        "callId" : callId
      }

      this.authenticationService.disconnectCall(data).subscribe((res) => {
        if (!res.isError) {
          if(this.translatedLanguage == 'nn'){
            this.translateService.get('Call Disconnected').subscribe((res:any)=>{
              this.callDisconnectedTranslation = res;
            
          })
          }
    
          else {
            this.callDisconnectedTranslation = 'Call Disconnected';
    
          }

          this.authenticationService.openSnackBar(this.callDisconnectedTranslation, 'Done', '5000','red-snackbar' ,'end','center'
);
         // console.log("inside success videocall"+JSON.stringify(res));
         if(this.userType == 'company'){
          this.router.navigate(['company/messagecompany'])
  
         }
  
         else {
          this.router.navigate(['customers/messagecustomer'])
  
         }
          // this.router.navigate(['/']);
         
        }
        
        else {
  
  
          // this.authenticationService.openSnackBar("Error while calling", 'Done', '5000','red-snackbar' ,'end','center');
        }
      }
        , (err: any) => {
  
          this.authenticationService.openSnackBar(err.error.message, 'Done', '5000','red-snackbar' ,'end','center'
);
          return;
  
        }
        
  
      ).add(() => {  this.pauseAudio() });
  

    }

    getCompanyLanguage(){
      this.authenticationService.getComapnyTranslation().subscribe((res:any)=>{
        this.translatedLanguage = res.language
      })
    }

    getUserLanguage(){
      this.authenticationService.getUserTranslation().subscribe((res:any)=>{
        this.translatedLanguage = res.language


      })
    }



    playAudio() {
      this.ringToneAudio = document.getElementById('ringToneAudio');
      if (this.ringToneAudio !== undefined) {
        this.ringToneAudio.loop = true;
        if (this.ringToneAudio.paused) {
          this.ringToneAudio.play();
        }
      }
    }
    pauseAudio() {
      if (this.ringToneAudio !== undefined) {
        this.ringToneAudio.pause();
        this.ringToneAudio.currentTime = 0;
      }
    }

    
  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 900000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  }


  

<app-header-customer></app-header-customer>

<section class="hjel_mobile_text mt-4 d-block d-sm-none">
    <!-- <p class="f_20 fw_medium text-center">Be helpful to yourself and others</p> -->
</section>

<section class="homepage_section d-none d-sm-block">
    <!-- <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="homepage_section text-center fw_regular">
                    <h2 class="text_black fw_medium mb-4">Find The Job That Fits Your Life.</h2>
                    <p class="f_20 fw_light text_black">
                        Each month, more than 7 million jobseekers turn to website in <br />
                        their search for work, making over <br />
                        160,000 applications every day.
                    </p>
                </div>
            </div>
        </div>
    </div> -->
</section>

<section class="mt-4 mt-sm-n5">
    <div class="container">
        <div class="row">
            <div class="col-sm-10 mx-auto">
                <div class="post_filters">
                    <form name="form1" #heroForm="ngForm" novalidate>
                        <div class="row">
                            <div class="col-sm-4 mb-3 mb-sm-0">
                                <mat-form-field appearance="fill" class="w-100 home_input_selector">
                                    <mat-label>{{'Select Location' | translate}}</mat-label>
                                    <mat-select [(ngModel)]="serviceArea" name="nametbtab2" #tbtab2 (ngModelChange)="getServiceListById(tbtab2.value)" #brndtab2="ngModel" required>
                                        <mat-option *ngFor="let srv of serviceAreaAll " [value]="srv._id">
                                            {{srv.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-4 mb-3 mb-sm-0">
                                <mat-form-field appearance="fill" class="w-100 home_input_selector">
                                    <mat-label>{{'Select Category' | translate}}</mat-label>
                                    <mat-select [(ngModel)]="serviceName" name="nametbtab3" #tbtab3 (ngModelChange)="getServiceListByName(tbtab3.value)" #brndtab3="ngModel" required>
                                        <mat-optgroup *ngFor="let group of serviceNameAll" [label]="translatedLanguage =='nn' ? group.nnName : group.name">
                                            <mat-option *ngFor="let srv of group.subcategrory" [value]="srv._id">
                                                {{translatedLanguage =='nn' ? srv.nnName : srv.serviceName}}

                                            </mat-option>
                                        </mat-optgroup>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-4 mb-1 mb-sm-0">
                                <button mat-button class="f_18 w-100 bg_green text-white p-2" (click)="search()" [disabled]="!heroForm.valid">{{'Search' | translate}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="mt-4 mt-sm-6">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 mx-auto">
                <div class="homepage_heading text-center fw_sitkab mb-6 mt-5 d-none d-sm-block">
                    <h4>{{'Explore Now' | translate}}</h4>
                    <h1 class="text_black">{{"Company's" | translate}} </h1>
                </div>
                <div *ngIf="postData && postData?.length>0">

                    <div class="categories-sec d-flex align-items-center justify-content-center flex-wrap pb-3">
                        <div class="cat-item carpenter d-flex justify-content-center">
                            <p>{{'Carpenter' | translate}}</p>
                        </div>
                        <div class="cat-item transport d-flex justify-content-center">
                            <p>{{'Transport' | translate}}</p>
                        </div>
                        <div class="cat-item cleaning d-flex justify-content-center">
                            <p>{{'Cleaning' | translate}}</p>
                        </div>
                    </div>

                    <div class="job_posts mb-4" [ngStyle]="{'border-color': data?.serviceType?.colorCode}" *ngFor="let data of postData | paginate: { itemsPerPage: itemsPerPage, currentPage: p,totalItems:totalItems};let i=index">
                        <div class="row">
                            <div class="col-md-2">
                                <div class="company_post" *ngIf="data?.galleryImages?.length<1">
                                    <img src="assets/images/noimageavailable.png" alt="" class="img-fluid" />
                                </div>
                                <ng-container *ngIf="data?.galleryImages?.length==1">
                                    <div class="company_post" *ngFor="let img of  data?.galleryImages">
                                        <img [src]="baseImgUrl+img" alt="" width="100%" />
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="data?.galleryImages?.length>1">
                                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                                        <div ngxSlickItem *ngFor="let img of  data?.galleryImages" class="slide">
                                            <div class="company_post">
                                                <img [src]="baseImgUrl+img" alt="" width="100%" />
                                            </div>
                                        </div>
                                    </ngx-slick-carousel>
                                </ng-container>
                            </div>
                            <div class="col-md-7 job_content_sec">
                                <h5 class="f_20 fw_medium">{{data?.companyName}}</h5>
                                <div class="d-flex flex-column-reverse flex-sm-row">
                                    <p class="f_18 mb-0 mr-3 d-flex align-self-center">
                                        <span class="location_map mr-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" id="photo-library" width="14.716" height="14.716" viewBox="0 0 14.716 14.716">
                                                <g id="photo-library-2" data-name="photo-library">
                                                    <path
                                                        id="Path_31588"
                                                        data-name="Path 31588"
                                                        d="M14.716,10.7V1.338A1.342,1.342,0,0,0,13.378,0H4.013A1.342,1.342,0,0,0,2.676,1.338V10.7A1.342,1.342,0,0,0,4.013,12.04h9.365A1.342,1.342,0,0,0,14.716,10.7ZM6.355,7.023,8.027,9.03l2.341-3.01,3.01,4.013H4.013ZM0,2.676v10.7a1.342,1.342,0,0,0,1.338,1.338h10.7V13.378H1.338V2.676Z"
                                                        fill="#1bbc9b"
                                                    />
                                                </g>
                                            </svg>
                                        </span> {{data?.averageRating? math.round(data?.averageRating):0}}
                                    </p>
                                    <div class="ratings mb-1 d-flex align-self-center">
                                        <star-rating value="{{data?.averageRating? math.round(data?.averageRating):0}}" [totalstars]="5" checkedcolor="#ffa749" uncheckedcolor="#bdc3c7" readonly="true" size="24px" (rate)="onRate($event)"></star-rating>

                                        <span class="reviews_counts align-self-center">{{data?.allReviews?.length}} {{'Reviews' | translate}}</span>
                                    </div>
                                </div>

                                <!-- <p class="text_gray fw_light mb-3 mb-sm-0 see_more_none">
                                    This user answers usually within 2 hours
                                </p> -->
                            </div>
                            <!-- <div class="col-sm-12 d-block d-sm-none">
                                <p class="text_gray fw_light mb-0 mt-3 mb-2">
                                    This user answers usually within 2 hours
                                </p>
                            </div> -->
                            <div class="col-md-3 align-self-center">
                                <button mat-button class="f_18 signup_button w-100 p-2" (click)="viewProfileCompany(data._id)">{{'View Profile' | translate}}</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="postData?.length>0">
                        <pagination-controls (pageChange)="p = $event;getHomePageData()" previousLabel="{{'Previous' | translate}}" nextLabel="{{'Next' | translate}}"></pagination-controls>
                    </div>
                </div>

                <div *ngIf="postData?.length<1" class="job_posts mb-4">
                    <p class="centerempty">{{'No Company available' | translate}}</p>
                </div>

                <!-- <div class="jobs_pagination my-5">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center justify-content-sm-end">
                            <li class="page-item disabled">
                                <a class="page-link" tabindex="-1">
                                    <span>
                                        <svg class="transfrom_180" xmlns="http://www.w3.org/2000/svg" width="20.243" height="13.501" viewBox="0 0 20.243 13.501">
                                            <g id="Group_19080" data-name="Group 19080" transform="translate(0)">
                                                <path
                                                    id="Icon_ionic-ios-arrow-round-back"
                                                    data-name="Icon ionic-ios-arrow-round-back"
                                                    d="M20.792,11.51a.919.919,0,0,0-.007,1.294l4.268,4.282H8.789a.914.914,0,0,0,0,1.828H25.053L20.777,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.792,11.51Z"
                                                    transform="translate(-7.882 -11.252)"
                                                    fill="#1DBF73"
                                                />
                                            </g>
                                        </svg>
                                    </span>
                                </a>
                            </li>
                            <li class="page-item"><a class="page-link active">1</a></li>
                            <li class="page-item"><a class="page-link">2</a></li>
                            <li class="page-item"><a class="page-link">3</a></li>
                            <li class="page-item">
                                <a class="page-link">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.243" height="13.501" viewBox="0 0 20.243 13.501">
                                            <g id="Group_19080" data-name="Group 19080" transform="translate(0)">
                                                <path
                                                    id="Icon_ionic-ios-arrow-round-back"
                                                    data-name="Icon ionic-ios-arrow-round-back"
                                                    d="M20.792,11.51a.919.919,0,0,0-.007,1.294l4.268,4.282H8.789a.914.914,0,0,0,0,1.828H25.053L20.777,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.792,11.51Z"
                                                    transform="translate(-7.882 -11.252)"
                                                    fill="#1DBF73"
                                                />
                                            </g>
                                        </svg>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div> -->
            </div>
        </div>
    </div>
</section>

<section class="sidemenu_fixed">
    <span class="fixed_active">
        <svg xmlns="http://www.w3.org/2000/svg" width="30.298" height="33.331" viewBox="0 0 30.298 33.331">
            <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(1.5 1.5)">
                <path
                    id="Path_28365"
                    data-name="Path 28365"
                    d="M4.5,13.616,18.149,3,31.8,13.616V30.3a3.033,3.033,0,0,1-3.033,3.033H7.533A3.033,3.033,0,0,1,4.5,30.3Z"
                    transform="translate(-4.5 -3)"
                    fill="none"
                    stroke="#1bbc9b"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                />
                <path id="Path_28366" data-name="Path 28366" d="M13.5,33.165V18h9.1V33.165" transform="translate(-4.401 -2.835)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
            </g>
        </svg>
    </span>

    <span class="" routerLink="/customers/uploadassignmentcustomer">
        <svg xmlns="http://www.w3.org/2000/svg" width="32.29" height="32.29" viewBox="0 0 32.29 32.29">
            <g id="Icon_feather-upload" data-name="Icon feather-upload" transform="translate(1.5 1.5)">
                <path
                    id="Path_31593"
                    data-name="Path 31593"
                    d="M33.79,22.5v6.509a3.254,3.254,0,0,1-3.254,3.254H7.754A3.254,3.254,0,0,1,4.5,29.009V22.5"
                    transform="translate(-4.5 -2.973)"
                    fill="none"
                    stroke="#1bbc9b"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                />
                <path id="Path_31594" data-name="Path 31594" d="M26.772,12.636,18.636,4.5,10.5,12.636" transform="translate(-3.991 -4.5)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                <path id="Path_31595" data-name="Path 31595" d="M18,4.5V24.027" transform="translate(-3.355 -4.5)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
            </g>
        </svg>
    </span>



    <span class="" [ngClass]="{'messageunread' : messageUnseenVal>0}" routerLink="/customers/messagecustomer">
    <!-- <span class="" [ngClass]="{'unread' : messageUnseenVal>0}" routerLink="/customers/messagecustomer"> -->

        <svg xmlns="http://www.w3.org/2000/svg" width="32.161" height="32.161" viewBox="0 0 32.161 32.161" >
            <path id="Icon_feather-message-square" data-name="Icon feather-message-square" d="M33.661,23.941a3.24,3.24,0,0,1-3.24,3.24H10.98L4.5,33.661V7.74A3.24,3.24,0,0,1,7.74,4.5H30.421a3.24,3.24,0,0,1,3.24,3.24Z" transform="translate(-3 -3)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
          </svg>
    </span>


</section>

<app-footercustomer></app-footercustomer>

<!-- Apply Job popup starts -->

<div class="modal fade custom_modal custom_modal_width" id="exampleModalCenter_view_profile" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header position-relative px-2 px-sm-3">
                <h5 class="modal-title text-center f_22 fw_medium w-100" id="exampleModalLongTitle">{{'Profile Details' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body px-2 px-sm-3">
                <div class="d-flex justify-content-center justify-content-sm-between align-items-start profile-top">
                    <div class="d-sm-flex px-1 px-sm-3 w-100">
                        <div class="user_profile">
                            <img class="avatar avatar-lg img-fluid" src="assets/images/noimageavailable.png" alt="..." *ngIf="!companyProfile?.logo" />
                            <img class="avatar avatar-lg img-fluid" [src]="baseImgUrlComp+companyProfile.logo" alt="..." *ngIf="companyProfile?.logo" />
                        </div>
                        <div class="user_info pl-0 pl-sm-3">
                            <h5 class="f_22 fw_medium text_black mb-4 mb-sm-3 mt-3 mt-sm-0 text-center text-sm-left">{{companyProfile?.companyName}}</h5>
                            <div>
                                <span>{{'Organization Number' | translate}}</span>
                                <p class="f_16 fw_medium text_black mb-2">{{companyProfile?.companyNumber | formatPhone}}</p>
                            </div>
                            <div>
                                <span>{{'Phone Number' | translate}}</span>
                                <p class="f_16 fw_medium text_black">{{companyProfile?.countryCode}} {{companyProfile?.phone | formatphonenew}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <button mat-button class="f_16 bg_green text-white mt-4 p-2 px-3" (click)="inviteToAssignment(companyProfile._id)">{{'Invite To An Assignment' | translate}}</button>
                    </div>
                </div>

                <div class="row mt-4 px-0 px-md-3 mx-mob-0">
                    <div class="col-sm-6">
                        <div class="user_reviews">
                            <h6 class="f_18 fw_medium position-relative mb-4">{{'Reviews' | translate}}</h6>

                            <ng-container *ngIf="companyProfile?.allReviews?.length<1">
                                <div class="d-flex">
                                    <div class="uploaded_image_size position-relative">
                                        <img src="assets/images/nocompanyreviews.png" alt="" class="img-fluid w-100 added_image" />
                                    </div>
                                </div>
                            </ng-container>
                            <div>
                                <ng-container *ngIf="companyProfile?.allReviews?.length==1">
                                    <div class="user_review_box" *ngFor="let img of companyProfile?.allReviews">
                                        <div class="user_rating_img mb-3">
                                            <img class="avatar avatar-lg img-fluid" src="assets/images/review-image.png" alt="..." *ngIf="!img?.review?.author?.profilePic" />
                                            <img class="avatar avatar-lg img-fluid" [src]="baseImgUrlComp+img.review.author.profilePic" alt="..." *ngIf="img?.review?.author?.profilePic" />
                                        </div>
                                        <h5 class="f_16 fw_medium text-black mb-1">{{img?.review?.author?.fullName}}</h5>
                                        <div class="ratings mb-1">
                                            <star-rating value="{{img?.review?.starCount ? math.round(img?.review?.starCount):0}}" [totalstars]="5" checkedcolor="#ffa749" uncheckedcolor="#bdc3c7" readonly="true" size="24px" (rate)="onRate($event)"></star-rating>
                                        </div>
                                        <p class="mb-0">{{img?.review?.text}}</p>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="companyProfile?.allReviews?.length>1">
                                    <ngx-slick-carousel (init)="slickInit($event)" class="carousel" #slickModala="slick-carousel" [config]="slideConfig2">
                                        <ng-container *ngIf="showcarsuel">
                                            <div ngxSlickItem *ngFor="let img of companyProfile?.allReviews" class="slide">
                                                <div class="user_review_box">
                                                    <div class="user_rating_img mb-3">
                                                        <img class="avatar avatar-lg img-fluid" src="assets/images/review-image.png" alt="..." *ngIf="!img?.review?.author?.profilePic" />
                                                        <img class="avatar avatar-lg img-fluid" [src]="baseImgUrlComp+img.review.author.profilePic" alt="..." *ngIf="img?.review?.author?.profilePic" />
                                                    </div>
                                                    <h5 class="f_16 fw_medium text-black mb-1">{{img?.review?.author?.fullName}}</h5>
                                                    <div class="ratings mb-1">
                                                        <star-rating value="{{img?.review?.starCount ? math.round(img?.review?.starCount):0}}" [totalstars]="5" checkedcolor="#ffa749" uncheckedcolor="#bdc3c7" readonly="true" size="24px" (rate)="onRate($event)"></star-rating>
                                                    </div>
                                                    <p class="mb-0">{{img?.review?.text}}</p>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ngx-slick-carousel>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="user_reviews">
                            <h6 class="f_18 fw_medium position-relative mb-4 mt-4 mt-md-0">{{'Uploaded Images' | translate}}</h6>
                            <div class="row">
                                <ng-container *ngIf="companyProfile?.galleryImages?.length>0">
                                    <div class="col-6 col-sm-4 mb-4 mb-sm-3" *ngFor="let images of companyProfile?.galleryImages">
                                        <div class="d-flex">
                                            <div class="uploaded_image_size position-relative">
                                                <img [src]="baseImgUrl+images" alt="" class="img-fluid w-100 added_image" />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="companyProfile?.galleryImages?.length<1">
                                    <div class="col-6 col-sm-4 mb-4 mb-sm-3">
                                        <div class="d-flex">
                                            <div class="uploaded_image_size position-relative">
                                                <img src="assets/images/noimageavailable.png" alt="" class="img-fluid w-100 added_image" />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Apply Job popup ends -->

<!-- Apply Job popup starts -->

<div class="modal fade custom_modal custom_modal_width" id="exampleModalCenter_invite_assignment" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header position-relative px-2 px-sm-3">
                <h5 class="modal-title text-center f_22 fw_medium w-100" id="exampleModalLongTitle" data-dismiss="modal">{{'Invite Assignment' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body px-2 px-sm-3 pb-0">

                <div class="invite_company_box">
                    <div class="invite_company_img">
                        <ng-container *ngIf="companyAssignmentList && companyAssignmentList?.length>0">
                            <div class="form-check pl-0 d-flex align-items-center bottom_border" *ngFor="let ttarea of companyAssignmentList;let i=index;">
                                <label class="btn btn-outline-primary search_long_image position-relative" for="btncheck{{i+1}}">
                                                <div class="d-flex align-items-center">
                                                    <div class="company_image" *ngIf="ttarea?.medias?.length<1">
                                                        <img src="assets/images/noimageavailable.png"  alt="" class="img-fluid" /> 
                                                       
                                                    </div>
                                                    <ng-container *ngIf="ttarea?.medias?.length==1">
                                                       
                                                           
                                                                <div class="company_image" *ngFor="let img of  ttarea?.medias">
                                                                  
                                                                    <img [src]="baseImgUrlAssign+img.media" alt="" width="100%">
                                                                </div>
                                                            
                                                    </ng-container>
                                                    <ng-container *ngIf="ttarea?.medias?.length>1">
                                                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" >
                                                            <ng-container *ngIf="showcarsuel">
                                                            <div ngxSlickItem *ngFor="let img of  ttarea?.medias" class="slide">
                                                                <div class="company_image" >
                                                                  
                                                                    <img [src]="baseImgUrlAssign+img.media" alt="" width="100%">
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        </ngx-slick-carousel>
                                                    </ng-container>
                                                    <p class="f_18 fw_medium ml-3 mb-0 text-left">
                                                        {{ttarea.headline}}
                                                        <span class="view_profile_text1">
                                                            
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.223" height="18.964" viewBox="0 0 14.223 18.964">
                                                                <path
                                                                    id="Icon_awesome-map-marker-alt"
                                                                    data-name="Icon awesome-map-marker-alt"
                                                                    d="M6.381,18.582C1,10.78,0,9.979,0,7.112a7.112,7.112,0,1,1,14.223,0c0,2.867-1,3.668-6.381,11.47a.889.889,0,0,1-1.462,0Zm.731-8.507A2.963,2.963,0,1,0,4.148,7.112,2.963,2.963,0,0,0,7.112,10.075Z"
                                                                    transform="translate(0)"
                                                                    fill="#464646"
                                                                />
                                                            </svg>
                                                       
                                                        {{ttarea?.assignmentArea?.name}}</span>
                                                        <!-- <span >View Profile</span> -->
                                                    </p>
                                                   
                                                   
                                                </div>
                                            </label>
                                <mat-checkbox class="btn-check" id="btncheck{{i+1}}" autocomplete="off" [ngModelOptions]="{standalone: true}" [checked]="ttarea.State" [(ngModel)]="ttarea.State" name="areaname11{{i}}" (change)="onPermissionCheckChangedCompany($event,ttarea._id)"> </mat-checkbox>
                            </div>
                        </ng-container>
                    </div>

                    <div *ngIf="companyAssignmentList?.length<1" class="job_posts mb-4">
                        <p class="centerempty">{{noCompanyAssignment}} </p>

                    </div>

                </div>


            </div>
            <div class="modal-footer">
                <button mat-button class="f_18 bg_green text-white mt-4 p-2 w-100" *ngIf="companyAssignmentList?.length<1">{{'Invite' | translate}}</button>
              <button mat-button class="f_18 bg_green text-white mt-4 p-2 w-100" *ngIf="companyAssignmentList?.length>=1" (click)="inviteCompanyAssign()">{{'Invite' | translate}}</button>

            </div>
        </div>
    </div>

    <!-- Apply Job popup ends -->
    <div *ngIf="isLoading">
        <div class="spinner_overlay"></div>
        <div class="loader-container">
            <div class="loader dots-wave">
                <span class="dot"></span>
                <span class="dot"></span>
                <span class="dot"></span>
            </div>
        </div>
    </div>
</div>
<app-header-company></app-header-company>

<section class="d-flex align-items-center splash_video_section h-sm-100">
    <div class="container">
        <div class="row mt-5 mt-md-0">
            <div class="col-md-5 align-self-center">
                <div class="left_side w-100">
                    <h5 class="f_16 text-black">{{aboutUs?.text}}</h5>
                    <div class="user_info">
                        <div class="d-flex align-items-center">
                            <span class="profile_icon_bg d-flex">
                                <svg xmlns="http://www.w3.org/2000/svg" class="green_block" width="12.413" height="17.93" viewBox="0 0 12.413 17.93">
                                    <path id="Icon_ionic-ios-pin" data-name="Icon ionic-ios-pin" d="M14.081,3.375A6,6,0,0,0,7.875,9.137c0,4.482,6.206,12.167,6.206,12.167s6.206-7.685,6.206-12.167A6,6,0,0,0,14.081,3.375Zm0,8.228A2.021,2.021,0,1,1,16.1,9.581,2.022,2.022,0,0,1,14.081,11.6Z" transform="translate(-7.875 -3.375)" fill="#ff3e3e"/>
                                  </svg>
                            </span>
                            <div class="ml-3">
                                <span>{{'Business Address' | translate}}</span>
                                <p class="f_16 fw_medium text_black mb-2">{{aboutUs?.address}}</p>
                            </div>

                        </div>
                        <div class="d-flex align-items-center">
                            <span class="profile_icon_bg d-flex">
                                <svg xmlns="http://www.w3.org/2000/svg" class="green_block" width="17.287" height="13.83" viewBox="0 0 17.287 13.83">
                                    <path id="Email" d="M18.559,6H4.729a1.726,1.726,0,0,0-1.72,1.729L3,18.1A1.734,1.734,0,0,0,4.729,19.83h13.83A1.734,1.734,0,0,0,20.287,18.1V7.729A1.734,1.734,0,0,0,18.559,6Zm0,3.457-6.915,4.322L4.729,9.457V7.729l6.915,4.322,6.915-4.322Z" transform="translate(-3 -6)" fill="#3773ff"/>
                                  </svg>
                            </span>
                            <div class="ml-3">
                                <span>{{'Contact Email' | translate}}</span>
                                <p class="f_16 fw_medium text_black">{{aboutUs?.email}}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-7 pl-md-5 mt-sm-0 mt-4">
                <div>
                    <video id="my_video_1" width="100%" height="350" controls preload="none" poster='assets/images/video_banner.png'>
                        <source [src]="baseImgUrlComp+aboutUs.media" type="video/mp4"  *ngIf="aboutUs"/>
                        <source src="assets/images/splash_video.mp4" type="video/mp4"  *ngIf="!aboutUs"/>
                    </video>

                </div>
            </div>
        </div>
    </div>
</section>

<section class="sidemenu_fixed">
    <span class="" routerLink="/company/homepagecompany">
        <svg xmlns="http://www.w3.org/2000/svg" width="30.298" height="33.331" viewBox="0 0 30.298 33.331">
            <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(1.5 1.5)">
              <path id="Path_28365" data-name="Path 28365" d="M4.5,13.616,18.149,3,31.8,13.616V30.3a3.033,3.033,0,0,1-3.033,3.033H7.533A3.033,3.033,0,0,1,4.5,30.3Z" transform="translate(-4.5 -3)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
              <path id="Path_28366" data-name="Path 28366" d="M13.5,33.165V18h9.1V33.165" transform="translate(-4.401 -2.835)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            </g>
        </svg>
    </span>

    <span class="" routerLink="/company/messagecompany">
        <svg xmlns="http://www.w3.org/2000/svg" width="32.161" height="32.161" viewBox="0 0 32.161 32.161">
            <path id="Icon_feather-message-square" data-name="Icon feather-message-square" d="M33.661,23.941a3.24,3.24,0,0,1-3.24,3.24H10.98L4.5,33.661V7.74A3.24,3.24,0,0,1,7.74,4.5H30.421a3.24,3.24,0,0,1,3.24,3.24Z" transform="translate(-3 -3)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
          </svg>
    </span>

    <span class="" routerLink="/company/profilecompany">
        <svg xmlns="http://www.w3.org/2000/svg" width="28.992" height="32.242" viewBox="0 0 28.992 32.242">
            <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(1.5 1.5)">
              <path id="Path_28367" data-name="Path 28367" d="M31.992,32.247V29a6.5,6.5,0,0,0-6.5-6.5h-13A6.5,6.5,0,0,0,6,29v3.249" transform="translate(-6 -3.006)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
              <path id="Path_28368" data-name="Path 28368" d="M25,11a6.5,6.5,0,1,1-6.5-6.5A6.5,6.5,0,0,1,25,11Z" transform="translate(-5.502 -4.5)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            </g>
          </svg>
    </span>
</section>

<app-footercompany></app-footercompany>

<div *ngIf="isLoading">
    <div class="spinner_overlay"></div>
    <div class="loader-container">
        <div class="loader dots-wave">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
        </div>
    </div>
</div>
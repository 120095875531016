<header class="splash_top_bar">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <nav class="navbar navbar-expand-lg navbar-dark bg-none flex-sm-row p-0 p-sm-2">
                  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                    <!-- <a class="navbar-brand" routerLink="/">
                        <img src="assets/images/logo_white.svg" alt="logo" class="img-fluid" />
                    </a> -->

                    <a class="navbar-brand" routerLink="/">
                      <img src="assets/images/logo_white.svg" alt="logo" class="img-fluid" />
                  </a>
                    
                    <!-- <span class="custom_icon_padding d-block d-sm-none"  routerLink="/customers/profilecustomer">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20.168" height="22.439" viewBox="0 0 20.168 22.439">
                        <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(-5 -3.5)">
                          <path id="Path_28367" data-name="Path 28367" d="M24.168,29.313V27.042A4.542,4.542,0,0,0,19.626,22.5H10.542A4.542,4.542,0,0,0,6,27.042v2.271" transform="translate(0 -4.374)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                          <path id="Path_28368" data-name="Path 28368" d="M21.084,9.042A4.542,4.542,0,1,1,16.542,4.5,4.542,4.542,0,0,1,21.084,9.042Z" transform="translate(-1.458)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </g>
                      </svg>
                    </span> -->
                  
                </nav>
            </div>
        </div>
    </div>
  </header>
  
  
  
 
  
  
  
<app-header-company></app-header-company>

<section class="d-flex align-items-center splash_video_section h-sm-100">
    <div class="container">
        <div class="row mt-5 mt-sm-0">
            <div class="col-sm-5 align-self-center">
                <div class="left_side w-100">
                    <h5 class="f_26 fw_semibold text-black mb-4">Contact Us</h5>
                    <form class="contact_form">
                        <mat-label class="d-block mb-2">Name</mat-label>
                        <mat-form-field appearance="fill" class="w-100 custom_textarea contact_form_fill mb-4">
                            <input matInput placeholder="Enter Name">
                        </mat-form-field>

                        <mat-label class="d-block mb-2">Email Address</mat-label>
                        <mat-form-field appearance="fill" class="w-100 custom_textarea contact_form_fill mb-4">
                            <input matInput placeholder="Enter Email Address">
                        </mat-form-field>

                        <mat-label class="d-block mb-2">Message</mat-label>
                        <mat-form-field appearance="fill" class="w-100 custom_textarea contact_form_fill">
                            <textarea matInput placeholder="Your subject"></textarea>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="col-sm-7 pl-md-5 mt-sm-0 mt-4 align-self-center">
                <div class="">
                    <img src="assets/images/contact_us.png" alt="img" class="img-fluid contact_img_edit" />
                    
                </div>
            </div>
        </div>
    </div>
</section>

<section class="sidemenu_fixed">
    <span class="" routerLink="/company/homepagecompany">
        <svg xmlns="http://www.w3.org/2000/svg" width="30.298" height="33.331" viewBox="0 0 30.298 33.331">
            <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(1.5 1.5)">
              <path id="Path_28365" data-name="Path 28365" d="M4.5,13.616,18.149,3,31.8,13.616V30.3a3.033,3.033,0,0,1-3.033,3.033H7.533A3.033,3.033,0,0,1,4.5,30.3Z" transform="translate(-4.5 -3)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
              <path id="Path_28366" data-name="Path 28366" d="M13.5,33.165V18h9.1V33.165" transform="translate(-4.401 -2.835)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            </g>
        </svg>
    </span>

    <span class="" routerLink="/company/messagecompany">
        <svg xmlns="http://www.w3.org/2000/svg" width="32.161" height="32.161" viewBox="0 0 32.161 32.161">
            <path id="Icon_feather-message-square" data-name="Icon feather-message-square" d="M33.661,23.941a3.24,3.24,0,0,1-3.24,3.24H10.98L4.5,33.661V7.74A3.24,3.24,0,0,1,7.74,4.5H30.421a3.24,3.24,0,0,1,3.24,3.24Z" transform="translate(-3 -3)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
          </svg>
    </span>

    <span class="" routerLink="/company/profilecompany">
        <svg xmlns="http://www.w3.org/2000/svg" width="28.992" height="32.242" viewBox="0 0 28.992 32.242">
            <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(1.5 1.5)">
              <path id="Path_28367" data-name="Path 28367" d="M31.992,32.247V29a6.5,6.5,0,0,0-6.5-6.5h-13A6.5,6.5,0,0,0,6,29v3.249" transform="translate(-6 -3.006)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
              <path id="Path_28368" data-name="Path 28368" d="M25,11a6.5,6.5,0,1,1-6.5-6.5A6.5,6.5,0,0,1,25,11Z" transform="translate(-5.502 -4.5)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            </g>
          </svg>
    </span>
</section>

<app-footercompany></app-footercompany>
<header class="splash_top_bar splash_mobile_bar mb-sm-0 mb-5">
    <!-- <div class="container d-sm-block d-none">
        <div class="row d-flex align-items-center">
            <div class="col-sm-10">
                <nav class="navbar navbar-expand-lg navbar-light bg-none">
                    <a class="navbar-brand" href="#">
                        <img src="assets/images/logo_white.svg" alt="logo" class="img-fluid d-sm-block d-none" />
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </nav>
            </div>

            <div class="col-sm-2">
<select class="form-control select_language" id="exampleFormControlSelect1" (change)="switchLanguage($event.target.value)">
    <option value="en">English</option>
    <option value="nn">Norwegian</option>
  </select>
            </div>
        </div>
    </div>
    <img src="assets/images/logo_white.svg" alt="logo" class="img-fluid   d-sm-none d-block" /> -->


        <div class="container">
        <div class="row d-flex align-items-center justify-content-between">
            <div class="logo_mobile">
                <nav class="navbar navbar-expand-lg navbar-light bg-none">
                    <a class="navbar-brand" href="#">
                        <img src="assets/images/logo_white.svg" alt="logo" class="img-fluid" />
                    </a>
                    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button> -->
                </nav>
            </div>
            <div class="select_block">
<!-- <select class="form-control select_language" id="exampleFormControlSelect1" (change)="switchLanguage($event.target.value)">
    <option value="en">English</option>
    <option value="nn">Norwegian</option>
  </select> -->

  <mat-select [(value)]="selected" class="form-control select_language" id="exampleFormControlSelect1" (selectionChange)="switchLanguage($event.value)">
    <mat-option value="en">
        English
    </mat-option>
    <mat-option value="nn">
        Norwegian
    </mat-option>
  </mat-select>
            </div>
        </div>
    </div>

</header>

<section class="d-flex align-items-center splash_video_section h-sm-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-5 align-self-center">
                <div class="left_side w-100">
                    <h4 class="f_22 fw_medium">
                        {{'Welcome To' | translate}}
                        <span class="fw_sitkab d-block hjelpsom_heading text-black mb-3 mt-2">Hjelpsom</span>
                    </h4>
                    <h5 class="text_gray">Vær hjelpsom for deg selv og andre!</h5>
                    <div class="d-sm-block d-none">
                        <div class="action_buttons mt-4 d-flex justify-content-between">
                            <button mat-button class="bg_green text-white f_18" routerLink="/login" >{{'Login' | translate}}</button>
                            <button mat-button class="f_18 signup_button" routerLink="/signup">{{'Signup' | translate}}</button>
                        </div>
                        <button mat-button class="f_18 w-100 fw_medium how_works_button mt-4" routerLink="/about-us">{{'How It Works' | translate}}</button>
                    </div>
                </div>
            </div>
            <div class="col-sm-7 pl-md-5 mt-sm-0 mt-4">
                <!-- <div class="right_side position-relative"> -->
                <!-- <video width="100%" height="350" autoplay loop>
                        <source src="assets/images/splash_video.mp4" type="video/mp4" />
                    </video> -->
                <!-- <video id="my_video_1" class="video-js vjs-default-skin"  width="100%" height="350" controls preload="none"
                    poster='http://video-js.zencoder.com/oceans-clip.jpg'
                    data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>
                    <source src="assets/images/splash_video.mp4" type='video/mp4' />
                    
                </video> -->

                <!-- <div id="instructions"> -->
                <video id="my_video_1" width="100%" height="350" controls preload="none" poster='assets/images/video_banner.png'>
                        <source src="assets/images/splash_video.mp4" type='video/mp4' />
                        <!-- <source src="https://vjs.zencdn.net/v/oceans.webm" type='video/webm' /> -->
                    </video>
                <!-- <button (click)="playPause()">Play/Pause</button>
                    <button (click)="makeBig()">Big</button>
                    <button (click)="makeSmall()">Small</button>
                    <button (click)="makeNormal()">Normal</button>
                    <button (click)="restart();">Restart</button>
                    <button (click)="skip(-10)">Rewind</button>
                    <button (click)="skip(10)">Fastforward</button>
                </div> -->



                <!-- </div> -->
                <div class="d-sm-none d-block">
                    <div class="action_buttons mt-4 d-flex justify-content-between">
                        <button mat-button class="bg_green text-white f_18" routerLink="/login">{{'Login' | translate}}</button>
                        <button mat-button class="f_18 signup_button" routerLink="/signup">{{'Signup' | translate}}</button>
                    </div>
                    <button mat-button class="f_18 w-100 fw_medium how_works_button mt-4 mb-3" routerLink="/about-us">{{'How It Works' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</section>
<header class="splash_top_bar d-block d-sm-none">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <nav class="navbar navbar-expand-lg navbar-dark bg-none flex-sm-row p-0 p-sm-2 justify-content-between">
                    <span routerLink="/splashscreen">
                        <svg xmlns="http://www.w3.org/2000/svg" width="11.729" height="20.516" viewBox="0 0 11.729 20.516">
                            <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M14.786,16.449l7.763-7.757a1.466,1.466,0,0,0-2.077-2.07l-8.795,8.789a1.463,1.463,0,0,0-.043,2.022l8.831,8.85a1.466,1.466,0,1,0,2.077-2.07Z" transform="translate(-11.251 -6.194)" fill="#fff"/>
                          </svg>
                    </span>
                    <a class="navbar-brand" href="#">
                        <img src="assets/images/logo_white.svg" alt="logo" class="img-fluid" />
                    </a>
                    
                    <span class="d-flex align-items-center p-0">
                    </span>
                </nav>
            </div>
        </div>
    </div>
</header>


<section>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="homepage_heading text-center fw_sitkab mt-5">
                    <!-- <h4>Working Process </h4> -->
                    <h1 class="text_black">{{'How it works' | translate}}</h1>
                </div>
                <div class="mt-5 about_slider">
                    <div class="owl-carousel owl-theme">
                        <!-- <div class="about_us_slider">
                            <img src="assets/images/create-account.svg" alt="" class="img-fluid mb-4" />
                            <h5 class="text-center f_22 fw_medium text_black mb-3">{{'Create An Account' | translate}}</h5>
                            <p class="text-center text_gray fw_light">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed convallis leo in nulla ullamrper, sed fringilla erat sagittis. Aliquam.</p>
                        </div>

                        <div class="about_us_slider">
                            <img src="assets/images/search-jobs.svg" alt="" class="img-fluid mb-4" />
                            <h5 class="text-center f_22 fw_medium text_black mb-3">{{'Search Jobs' | translate}}</h5>
                            <p class="text-center text_gray fw_light">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed convallis leo in nulla ullamrper, sed fringilla erat sagittis. Aliquam.</p>
                        </div>

                        <div class="about_us_slider">
                            <img src="assets/images/Save-apply.svg" alt="" class="img-fluid mb-4" />
                            <h5 class="text-center f_22 fw_medium text_black mb-3">{{'Save and Apply' | translate}}</h5>
                            <p class="text-center text_gray fw_light">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed convallis leo in nulla ullamrper, sed fringilla erat sagittis. Aliquam.</p>
                        </div> -->

                          <div class="about_us_slider">
                            <img src="assets/images/exit.png" alt="" class="img-fluid mb-4" />
                            <h5 class="text-center f_22 fw_medium text_black mb-3">{{'Register your company' | translate}}</h5>
                            <p class="text-center text_gray fw_light">{{'you will need to provide a Norwegian organization number and a Norwegian mobile number.' | translate}}</p>
                        </div>

                        <div class="about_us_slider">
                            <img src="assets/images/clock.png" alt="" class="img-fluid mb-4" />
                            <h5 class="text-center f_22 fw_medium text_black mb-3">{{'Soon enough' | translate}}</h5>
                            <p class="text-center text_gray fw_light">{{'our moderators will review your request and contact you if needed. Ultimaltely you will be notified by email when the account is verified.' | translate}}</p>
                        </div>

                        <div class="about_us_slider">
                            <img src="assets/images/Save-apply.svg" alt="" class="img-fluid mb-4" />
                            <h5 class="text-center f_22 fw_medium text_black mb-3">{{'Complete your profile' | translate}}</h5>
                            <p class="text-center text_gray fw_light">{{'and start exploring available jobs, receive invitations and talk with customers directly.' | translate}}</p>
                        </div>

                        <div class="about_us_slider">
                          
                        </div>

                        
                        
                    </div>
                    <button mat-button class="f_18 w-100 bg_green text-white mt-4 p-2" (click) ="redirectToSplashScreen()">{{'Continue' | translate}}</button>


                </div>
            </div>
        </div>
    </div>
    
</section>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  languageSelected: any;
  enableNavigation:boolean = false;

  constructor( private translateService:TranslateService, private router:Router) { }

  ngOnInit(): void {
    this.languageSelected = localStorage.getItem('selectLanguage');
    console.log(this.languageSelected)
    this.translateService.use(this.languageSelected)

    $(document).ready(function(){
      $('.owl-carousel').owlCarousel();
    });
    
    $('.owl-carousel').owlCarousel({
      loop:true,
      margin:10,
      nav:false,
      dots:true,
      dotsEach:true,
      mouseDrag: false,
      touchDrag: false  ,     
      responsiveClass:true,
      rewind:true,
      onChanged:(e) =>{
        console.log(e)
        console.log(e.page.index)
        
        if(e.page.index == '3' && this.enableNavigation == true){
           this.router.navigate(['/login'])
        //  setTimeout(() => {
        //     this.router.navigate(['/login'])
        //   }, 400);
        }
        
      },
      responsive:{
          0:{
              items:1,
          },
          600:{
              items:1,
              nav:false
          },
          1000:{
              items:1,
              loop:true
          }
      }
  })
}

  redirectToSplashScreen(){
    this.enableNavigation = true

    var owl = $('.owl-carousel');
    owl.owlCarousel();
    owl.trigger('next.owl.carousel',[300]);
    console.log(owl);
  }
}


<app-header-company></app-header-company>

<section class="my-5 no-scroll-height">
    <div class="container">
        <div class="row">
            <div class="col-sm-11 mx-auto py-1">
                <div class="profile_section">
                    <h1 class="text-center mb-5 d-none d-sm-block">
                        {{'My Profile' | translate}}
                        <span></span>
                        <span></span>
                    </h1>
                    <div class="mb-4">
                        <button mat-button class="bg_green f_14 edit_profile_btn fw_medium d-block d-md-none ml-auto mr-3" routerLink="/company/editprofilecompany">{{'Edit Profile' | translate}}</button>
                    </div>
                    <div class="d-flex justify-content-center justify-content-sm-between align-items-start">
                        <div class="d-sm-flex px-3">
                            <div class="user_profile">
                                <!-- <img class="avatar avatar-lg img-fluid" src="assets/images/user.jpg" alt="..." /> -->
                                <img class="avatar avatar-lg img-fluid" src="assets/images/noimageavailable.png" alt="..." *ngIf="!companyProfile?.logo" />
                                <img class="avatar avatar-lg img-fluid" [src]="baseImgUrlComp+companyProfile.logo" alt="..." *ngIf="companyProfile?.logo" />
                            </div>
                            <div class="user_info pl-0 pl-sm-3">
                                <h5 class="f_22 fw_medium text_black mb-4 mb-sm-3 mt-3 mt-sm-0 text-center text-sm-left">{{companyProfile?.companyName}}</h5>
                                <div>
                                    <span>{{'Organization Number' | translate}}</span>
                                    <p class="f_16 fw_medium text_black mb-2">{{companyProfile?.companyNumber | formatPhone}}</p>
                                </div>
                                <div>
                                    <span>{{'Phone Number' | translate}}</span>
                                    <p class="f_16 fw_medium text_black">{{companyProfile?.countryCode}} {{companyProfile?.phone | formatphonenew}}</p>
                                </div>
                            </div>
                        </div>
                        <button mat-button class="bg_green f_14 edit_profile_btn fw_medium d-none d-md-block" routerLink="/company/editprofilecompany">{{'Edit Profile' | translate}}</button>
                    </div>

                    <div class="px-3">
                        <div class="row mt-4">
                            <div class="col-md-7 bottom-content">
                                <div class="user_reviews">
                                    <h6 class="f_18 fw_medium position-relative mb-4">{{'Uploaded Images' | translate}}</h6>
                                    <div class="row">
                                        <!-- <div class="col-sm-4">
                                        <div class="add_photos position-relative mb-4 mb-sm-3">
                                            <input type="file" class="form-control" id="inputEmail4" placeholder="Search Country">
                                            <div class="add_photo_uploader">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21.5" height="21.5" viewBox="0 0 21.5 21.5">
                                                    <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(0.75 0.75)">
                                                      <path id="Path_3734" data-name="Path 3734" d="M18,7.5v20" transform="translate(-8 -7.5)" fill="none" stroke="#00b7fb" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                                      <path id="Path_3735" data-name="Path 3735" d="M7.5,18h20" transform="translate(-7.5 -8)" fill="none" stroke="#00b7fb" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                                    </g>
                                                  </svg>
                                                <p class="mb-0 mt-2">Add Image</p>
                                            </div>
                                        </div>
                                    </div> -->

                                        <ng-container *ngIf="companyProfile?.galleryImages?.length>0">
                                            <div class="col-6 col-sm-4 mb-4 mb-sm-3" *ngFor="let images of companyProfile?.galleryImages">
                                                <div class="d-flex">
                                                    <div class="uploaded_image_size position-relative">
                                                        <img [src]="baseImgUrl+images" alt="" class="img-fluid w-100 added_image">
                                                    </div>

                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="companyProfile?.galleryImages?.length<1">
                                            <div class="col-6 col-sm-4 mb-4 mb-sm-3">
                                                <div class="d-flex">
                                                    <div class="uploaded_image_size position-relative">
                                                        <img src="assets/images/noimageavailable.png" alt="" class="img-fluid w-100 added_image">
                                                    </div>

                                                </div>
                                            </div>
                                        </ng-container>


                                    </div>

                                </div>

                                <div class="user_reviews">
                                    <h6 class="f_18 fw_medium position-relative mb-4">{{'Reviews' | translate}}</h6>
                                    <ng-container *ngIf="companyProfile?.allReviews?.length<1">
                                        <div class="d-flex">
                                            <div class="uploaded_image_size position-relative">
                                                <img src="assets/images/nocompanyreviews.png" alt="" class="img-fluid w-100 added_image">
                                            </div>

                                        </div>
                                    </ng-container>
                                    <div>

                                        <ng-container *ngIf="companyProfile?.allReviews?.length==1">


                                            <div class="user_review_box" *ngFor="let img of companyProfile?.allReviews">
                                                <div class="user_rating_img mb-3">
                                                    <img class="avatar avatar-lg img-fluid" src="assets/images/review-image.png" alt="..." *ngIf="!img?.review?.author?.profilePic" />
                                                    <img class="avatar avatar-lg img-fluid" [src]="baseImgUrlComp+img.review.author.profilePic" alt="..." *ngIf="img?.review?.author?.profilePic" />
                                                </div>
                                                <h5 class="f_16 fw_medium text-black mb-1">{{img?.review?.author?.fullName}}</h5>
                                                <div class="ratings mb-1">
                                                    <star-rating value="{{img?.review?.starCount ? math.round(img?.review?.starCount):0}}" [totalstars]="5" checkedcolor="#ffa749" uncheckedcolor="black" readonly="true" size="24px" (rate)="onRate($event)"></star-rating>

                                                </div>
                                                <p class="mb-0">{{img?.review?.text}}</p>

                                            </div>



                                        </ng-container>
                                        <ng-container *ngIf="companyProfile?.allReviews?.length>1">
                                            <ngx-slick-carousel (init)="slickInit($event)" class="carousel" #slickModala="slick-carousel" [config]="slideConfig2">
                                                <ng-container *ngIf="showcarsuel">
                                                    <div ngxSlickItem *ngFor="let img of companyProfile?.allReviews" class="slide">
                                                        <div class="user_review_box">
                                                            <div class="user_rating_img mb-3">
                                                                <img class="avatar avatar-lg img-fluid" src="assets/images/review-image.png" alt="..." *ngIf="!img?.review?.author?.profilePic" />
                                                                <img class="avatar avatar-lg img-fluid" [src]="baseImgUrlComp+img.review.author.profilePic" alt="..." *ngIf="img?.review?.author?.profilePic" />
                                                            </div>
                                                            <h5 class="f_16 fw_medium text-black mb-1">{{img?.review?.author?.fullName}}</h5>
                                                            <div class="ratings mb-1">
                                                                <star-rating value="{{img?.review?.starCount ? math.round(img?.review?.starCount) : 0}}" [totalstars]="5" checkedcolor="#ffa749" uncheckedcolor="black" readonly="true" size="24px" (rate)="onRate($event)"></star-rating>

                                                            </div>
                                                            <p class="mb-0">{{img?.review?.text}}</p>

                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </ngx-slick-carousel>

                                        </ng-container>
                                    </div>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="sidemenu_fixed">
    <span class="" routerLink="/company/homepagecompany">
        <svg xmlns="http://www.w3.org/2000/svg" width="30.298" height="33.331" viewBox="0 0 30.298 33.331">
            <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(1.5 1.5)">
              <path id="Path_28365" data-name="Path 28365" d="M4.5,13.616,18.149,3,31.8,13.616V30.3a3.033,3.033,0,0,1-3.033,3.033H7.533A3.033,3.033,0,0,1,4.5,30.3Z" transform="translate(-4.5 -3)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
              <path id="Path_28366" data-name="Path 28366" d="M13.5,33.165V18h9.1V33.165" transform="translate(-4.401 -2.835)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            </g>
        </svg>
    </span>

    <!-- <span class="messageunread unread" routerLink="/company/messagecompany" [ngClass]="{'messageunread' : messageUnseenVal>0}"> -->
    <span class="" routerLink="/company/messagecompany" [ngClass]="{'messageunread' : messageUnseenVal>0}">

        <svg xmlns="http://www.w3.org/2000/svg" width="32.161" height="32.161" viewBox="0 0 32.161 32.161">
            <path id="Icon_feather-message-square" data-name="Icon feather-message-square" d="M33.661,23.941a3.24,3.24,0,0,1-3.24,3.24H10.98L4.5,33.661V7.74A3.24,3.24,0,0,1,7.74,4.5H30.421a3.24,3.24,0,0,1,3.24,3.24Z" transform="translate(-3 -3)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
          </svg>
    </span>

    <span class="fixed_active">
        <svg xmlns="http://www.w3.org/2000/svg" width="28.992" height="32.242" viewBox="0 0 28.992 32.242">
            <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(1.5 1.5)">
              <path id="Path_28367" data-name="Path 28367" d="M31.992,32.247V29a6.5,6.5,0,0,0-6.5-6.5h-13A6.5,6.5,0,0,0,6,29v3.249" transform="translate(-6 -3.006)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
              <path id="Path_28368" data-name="Path 28368" d="M25,11a6.5,6.5,0,1,1-6.5-6.5A6.5,6.5,0,0,1,25,11Z" transform="translate(-5.502 -4.5)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            </g>
          </svg>
    </span>
</section>

<app-footercompany></app-footercompany>


<!-- Job Details popup starts -->

<!-- <div class="modal fade custom_modal" id="exampleModalCenter_job_details" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header position-relative px-2 px-sm-3">
                <h5 class="modal-title text-center f_22 fw_medium w-100" id="exampleModalLongTitle">Image Details</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body px-2 px-sm-3 pt-0">
                <div class="image-full-size">
                    <img src="../../../assets/images/dummy_image.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- Job Details popup ends -->
<div *ngIf="isLoading">
    <div class="spinner_overlay"></div>
    <div class="loader-container">
        <div class="loader dots-wave">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
        </div>
    </div>
</div>
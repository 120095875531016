<app-header-company></app-header-company>

<section class="content_wrapper py-4 py-sm-5">
    <div class="container">
        <div class="row">
            <div class="col-sm-5">
                <div class="messages_left_side p-0 p-sm-3 messages_show" [ngClass]="{'mobile-hide': chatBox }">
                    <mat-tab-group class="messages_mat_tabs" [selectedIndex]="selectedTabIndex" (selectedIndexChange)="changeindex($event)">
                        <mat-tab label="{{'Messages' | translate}}">
                            <div class="messages_scroll" *ngIf="ischatlistShow">
                                <div class="media media-single message_show mb-3" (click)="setIndex(i, item)" *ngFor="let item of socketService.artistUserChatList | async; let i = index" [ngClass]="{'active_chat': userSelected?._id == item?._id}" style="cursor: pointer;">
                                    <a>
                                        <img class="avatar avatar-lg img-fluid" [src]="baseImgUrlCust+item?.user?.profilePic" alt="..." *ngIf="item?.user?.profilePic" />
                                        <img class="avatar avatar-lg img-fluid" src="assets/images/noimageavailable.png" alt="..." *ngIf="!item?.user?.profilePic" />
                                    </a>

                                    <div class="media-body ml-3">
                                        <h6>
                                            <a class="f_16 fw_medium text_black">{{item?.user?.fullName}} - {{item?.assignment?.headline}}</a>
                                            <!-- <span class="time-meta pull-right">18:18</span> -->
                                        </h6>
                                        <div class="d-flex justify-content-between">
                                            <p class="text-message f_14 fw_regular text_black mb-1">{{item?.lastMessageId?.message}}</p>
                                            <span class="messages_counts" *ngIf="item?.unSeenCountByCompany>0">{{item?.unSeenCountByCompany}}</span>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="(socketService.artistUserChatList | async)?.length === 0">
                                    {{'No Customer Available' | translate}}
                                </div>

                            </div>

                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <!-- <span class="unread">Invitations</span> -->
                                <span>{{'Invitations' | translate}}</span>

                            </ng-template>
                            <div class="messages_scroll">
                                <!-- <div class="invitation_box mb-3 unread" (click)="setIndexInvitation(i, item)" *ngFor="let item of outGoingInvitations ; let i = index" [ngClass]="{'active_chat': userSelectedinv?._id === item?._id}" style="cursor: pointer;"> -->
                                <div class="invitation_box mb-3" (click)="setIndexInvitation(i, item)" *ngFor="let item of outGoingInvitations ; let i = index" [ngClass]="{'active_chat': userSelectedinv?._id === item?._id}" style="cursor: pointer;">

                                    <a>
                                        <img class="avatar avatar-lg img-fluid" [src]="baseImgUrlCust+item?.user?.profilePic" alt="..." *ngIf="item?.user?.profilePic" />
                                        <img class="avatar avatar-lg img-fluid" src="assets/images/noimageavailable.png" alt="..." *ngIf="!item?.user?.profilePic" />
                                    </a>

                                    <div class="media-body ml-3">
                                        <h6 class="mb-0">
                                            <a class="f_16 fw_medium text_black">{{item?.user?.fullName}} - {{item?.assignment?.headline}}
                                                 <!-- <span class="invitation_date text_gray">{{items?.user?.fullName}}</span> -->
                                                
                                                </a>
                                            <div class="message_status_not_completed position-relative">{{'The mission is' | translate}} {{item?.status}}.</div>
                                        </h6>

                                    </div>
                                </div>
                                <div *ngIf="outGoingInvitations?.length === 0">
                                    {{'No Invitation Available' | translate}}
                                </div>

                                <!-- <div class="invitation_box mb-3">
                                    <a href="#">
                                        <img class="avatar avatar-lg img-fluid" src="assets/images/user.jpg" alt="..." />
                                    </a>
    
                                    <div class="media-body ml-3">
                                        <h6 class="mb-0">
                                            <a class="f_16 fw_medium text_black">Hossein Shams</a>
                                            <div class="message_status_waiting position-relative">Waiting for reply.</div>
                                        </h6>
                                        
                                    </div>
                                </div>
                                
                                <div class="invitation_box mb-3">
                                    <a href="#">
                                        <img class="avatar avatar-lg img-fluid" src="assets/images/user.jpg" alt="..." />
                                    </a>
    
                                    <div class="media-body ml-3">
                                        <h6 class="mb-0">
                                            <a class="f_16 fw_medium text_black">Hossein Shams</a>
                                            <div class="message_status_invited position-relative">You have been invited.</div>
                                        </h6>
                                        
                                    </div>
                                </div>
    
                                <div class="invitation_box mb-3">
                                    <a href="#">
                                        <img class="avatar avatar-lg img-fluid" src="assets/images/user.jpg" alt="..." />
                                    </a>
    
                                    <div class="media-body ml-3">
                                        <h6 class="mb-0">
                                            <a class="f_16 fw_medium text_black">Hossein Shams</a>
                                            <div class="message_status_not_completed position-relative">The mission is not completed.</div>
                                        </h6>
                                        
                                    </div>
                                </div>
    
                                <div class="invitation_box mb-3">
                                    <a href="#">
                                        <img class="avatar avatar-lg img-fluid" src="assets/images/user.jpg" alt="..." />
                                    </a>
    
                                    <div class="media-body ml-3">
                                        <h6 class="mb-0">
                                            <a class="f_16 fw_medium text_black">Hossein Shams</a>
                                            <div class="message_status_waiting position-relative">Waiting for reply.</div>
                                        </h6>
                                        
                                    </div>
                                </div>
                                
                                <div class="invitation_box mb-3">
                                    <a href="#">
                                        <img class="avatar avatar-lg img-fluid" src="assets/images/user.jpg" alt="..." />
                                    </a>
    
                                    <div class="media-body ml-3">
                                        <h6 class="mb-0">
                                            <a class="f_16 fw_medium text_black">Hossein Shams</a>
                                            <div class="message_status_invited position-relative">You have been invited.</div>
                                        </h6>
                                        
                                    </div>
                                </div> -->
                            </div>

                        </mat-tab>
                        <mat-tab label="{{'Completed' | translate}}">
                            <div class="messages_scroll" >
                                <div class="invitation_box mb-3" (click)="setIndexInvitationcomp(i, item)" *ngFor="let item of completedList ; let i = index">
                                    <a>
                                        <img class="avatar avatar-lg img-fluid" [src]="baseImgUrlCust+item?.user?.profilePic" alt="..." *ngIf="item?.user?.profilePic" />
                                        <img class="avatar avatar-lg img-fluid" src="assets/images/noimageavailable.png" alt="..." *ngIf="!item?.user?.profilePic" />
                                    </a>

                                    <div class="media-body ml-3">
                                        <h6 class="mb-0">
                                            <a class="f_16 fw_medium text_black">{{item?.user?.fullName}} - {{item?.assignment?.headline}}
                                                 <!-- <span class="invitation_date text_gray">{{items?.user?.fullName}}</span> -->
                                                
                                                </a>
                                            <div class="message_status_not_completed position-relative">{{'The mission is completed.' | translate}}</div>
                                        </h6>

                                    </div>
                                </div>

                                <div *ngIf="completedList?.length === 0">
                                    {{'No Invitation Available' | translate}}
                                </div>


                            </div>


                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>

            <div class="col-sm-7" *ngIf="ischatBoxShow && isSelectedChat && selectedTabIndex==0">
                <div class="messages_left_side chatbox_show" [ngClass]="{'mobile-show': chatBox}">
                    <div class="media media-single px-3 pt-3 pb-2 mx-0 align-items-center">

                        <span class="mr-3 btn-back" (click)="goBack()"><svg height="18.136" viewBox="0 0 11.058 18.136" width="11.058" xmlns="http://www.w3.org/2000/svg"><path  d="M14.584,15.26,21.9,8.4a1.234,1.234,0,0,0,0-1.83,1.455,1.455,0,0,0-1.958,0l-8.292,7.769a1.236,1.236,0,0,0-.04,1.787l8.327,7.823a1.457,1.457,0,0,0,1.958,0,1.234,1.234,0,0,0,0-1.83Z" data-name="Icon ionic-ios-arrow-back" fill="#212121" id="Icon_ionic-ios-arrow-back" transform="translate(-11.251 -6.194)"></path></svg></span>

                        <a style="cursor: pointer;" title="View Profile" (click)="viewProfileCompany(userSelected?.user._id)">
                            <img class="avatar avatar-lg img-fluid" [src]="baseImgUrlCust+ userSelected?.user?.profilePic" alt="..." *ngIf="userSelected?.user?.profilePic" />
                            <img class="avatar avatar-lg img-fluid" src="assets/images/noimageavailable.png" alt="..." *ngIf="!userSelected?.user?.profilePic" />
                        </a>

                        <div class="media-body ml-3 d-flex justify-content-between">
                            <h6>
                                <a class="f_16 fw_medium text_black">{{ userSelected?.user?.fullName}} - {{userSelected?.assignment?.headline}}</a>
                                <!-- <span class="time-meta pull-right">18:18</span> -->
                                <!-- <p class="f_14 text_gray mt-2">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.706" height="15.295" viewBox="0 0 10.706 15.295">
                                            <path
                                                id="placeholder"
                                                d="M12.853,3A5.349,5.349,0,0,0,7.5,8.353c0,4.015,5.353,9.941,5.353,9.941s5.353-5.927,5.353-9.941A5.349,5.349,0,0,0,12.853,3Zm0,7.265a1.912,1.912,0,1,1,1.912-1.912A1.913,1.913,0,0,1,12.853,10.265Z"
                                                transform="translate(-7.5 -3)"
                                                fill="#a8a8a8"
                                            />
                                        </svg>
                                    </span>
                                    Majorstua, 1.0 Miles
                                </p> -->
                            </h6>

                        </div>
                    </div>

                    <div class="message_status position-relative my-4">{{'This assignment is approved' | translate}} .</div>
                    <ng-container *ngIf="ischatBoxShow && isSelectedChat">
                        <div class="message pb-0 pb-sm-2 messages_scrollmessagecontent" #scrollMe [scrollTop]="scrollMe.scrollHeight">
                            <ul class="chat-list mt-1 px-1 " *ngFor="let item of userChatMessages | async">
                                <!--chat Row -->
                                <li *ngIf="item.sender=='user'">
                                    <div class="chat-img"><img *ngIf="item?.user?.profilePic" [src]="baseImgUrlCust+item?.user?.profilePic" alt="user" class="img-fluid" />
                                        <img *ngIf="!item?.user?.profilePic" src="assets/images/noimageavailable.png" alt="user" class="img-fluid" />
                                    </div>
                                    <div class="chat-content" *ngIf="!item.isVideoCall">
                                        <div class="chating-box">
                                            {{item.message}}
                                            <button class="btn translate" (click)="transtxt(item)" data-toggle="modal" data-target="#translate_message"><img src="assets/images/translate.svg"> {{'Translate' | translate}}</button>
                                        </div>
                                        <div class="time-meta">{{item.sendTime | date: 'shortTime'}}</div>
                                    </div>

                                    <div class="chat-content" *ngIf="item.isVideoCall">
                                        <div class="chating-box"><b><u>{{'Video Call Done' | translate}}</u></b></div>
                                        <div class="time-meta">{{item.start_time | date: 'shortTime'}}-{{(item.end_time?item.end_time:item.start_time) | date: 'shortTime'}}</div>
                                    </div>
                                </li>
                                <!--chat Row -->

                                <!--chat Row -->

                                <li class="reverse" *ngIf="item.sender=='company'">
                                    <div class="chat-content my-message" *ngIf="!item.isVideoCall">
                                        <div class="chating-box cl-white bg-primary">
                                            {{item.message}}
                                            <button class="btn translate ml-auto" (click)="transtxt(item)" data-toggle="modal" data-target="#translate_message"><img src="assets/images/translate.svg"> {{'Translate' | translate}}</button>
                                        </div>
                                        <div class="time-meta">{{item.sendTime | date: 'shortTime'}}</div>
                                    </div>
                                    <div class="chat-content my-message" *ngIf="item.isVideoCall">
                                        <div class="chating-box cl-white bg-primary"><b><u>{{'Video Call Done' | translate}}</u></b></div>
                                        <div class="time-meta">{{item.start_time | date: 'shortTime'}}-{{(item.end_time?item.end_time:item.start_time) | date: 'shortTime'}}</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <!-- <div class="message_suggestion px-3">
                            <span class="fw_medium text_black mb-1">Hi, we can arrange an inspection with video inspection</span>
                        </div> -->
                        <div class="reply-main position-relative p-3">
                            <span class="videocallicn"><img src="assets/images/videoicn.svg" (click)="videoCall(userSelected)" alt="" class="img-fluid cursor-pointer" /> </span>
                            <input class="form-control" rows="1" id="comment" placeholder="{{'Type your message' | translate}}...." #messageToBoSend (keyup.enter)="sendMessage(messageToBoSend.value)" />
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg"  id="Send_btn" width="24.372" height="18.638" viewBox="0 0 24.372 18.638" (click)="sendMessage(messageToBoSend.value)">
                                    <g id="send_1_" data-name="send (1)" transform="translate(0 0)">
                                        <g id="Group_618" data-name="Group 618">
                                            <path id="Path_1175" data-name="Path 1175" d="M.012,0,0,7.248,17.409,9.319,0,11.39l.012,7.248L24.372,9.319Z" fill="#1bbc9b" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </ng-container>



                </div>
            </div>

            <div class="col-sm-7 d-flex" *ngIf="!ischatBoxShow && !isSelectedChat && selectedTabIndex==0">
                <div class="messages_left_side chatbox_show no-chat-bax text-center">
                    <h2>{{'Messages' | translate}}</h2>
                    <p>{{'Is a list of all active conversations that you have ongoing with companies' | translate}}</p>
                    <img src="assets/images/chat-image.svg">
                </div>
            </div>
            <!-- <ng-template #elsepart>Show Image</ng-template> -->
            <!-- second tab -->

            <div class="col-sm-7" *ngIf="isOutgoingSelected && selectedTabIndex==1">
                <div class="messages_left_side chatbox_show" [ngClass]="{'mobile-show': chatBox}">
                    <div class="media media-single px-3 pt-3 pb-2 align-items-center">
                        <span class="mr-3 btn-back" (click)="goBack()"><svg height="18.136" viewBox="0 0 11.058 18.136" width="11.058" xmlns="http://www.w3.org/2000/svg"><path  d="M14.584,15.26,21.9,8.4a1.234,1.234,0,0,0,0-1.83,1.455,1.455,0,0,0-1.958,0l-8.292,7.769a1.236,1.236,0,0,0-.04,1.787l8.327,7.823a1.457,1.457,0,0,0,1.958,0,1.234,1.234,0,0,0,0-1.83Z" data-name="Icon ionic-ios-arrow-back" fill="#212121" id="Icon_ionic-ios-arrow-back" transform="translate(-11.251 -6.194)"></path></svg></span>
                        <a>
                            <img class="avatar avatar-lg img-fluid" [src]="baseImgUrlCust+ userSelectedinv?.user?.profilePic" alt="..." *ngIf="userSelectedinv?.user?.profilePic" />
                            <img class="avatar avatar-lg img-fluid" src="assets/images/noimageavailable.png" alt="..." *ngIf="!userSelectedinv?.user?.profilePic" />
                        </a>

                        <div class="media-body ml-3 d-flex justify-content-between">
                            <h6>
                                <a class="f_16 fw_medium text_black"> {{ userSelectedinv?.user?.fullName}} - {{userSelectedinv?.assignment?.headline}}</a>
                                <!-- <span class="time-meta pull-right">18:18</span> -->
                                <!-- <p class="f_14 text_gray mt-2">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.706" height="15.295" viewBox="0 0 10.706 15.295">
                                            <path
                                                id="placeholder"
                                                d="M12.853,3A5.349,5.349,0,0,0,7.5,8.353c0,4.015,5.353,9.941,5.353,9.941s5.353-5.927,5.353-9.941A5.349,5.349,0,0,0,12.853,3Zm0,7.265a1.912,1.912,0,1,1,1.912-1.912A1.913,1.913,0,0,1,12.853,10.265Z"
                                                transform="translate(-7.5 -3)"
                                                fill="#a8a8a8"
                                            />
                                        </svg>
                                    </span>
                                    Majorstua, 1.0 Miles
                                </p> -->
                            </h6>
                            <span><img src="assets/images/video-call.png" alt="" class="img-fluid cursor-pointer d-none" /> </span>
                        </div>
                    </div>

                    <div class="messages_show2" id="conversation">
                        <p class="f_16 text-black p-3"><br><br> {{userSelectedinv?.assignment?.description}}
                            <br>
                            <br> {{'Please let me know if you are interested in helping me out.' | translate}}
                            <br>
                            <br> {{'Best Regards.' | translate}}
                            <br> {{ userSelectedinv?.user?.fullName}}
                        </p>
                        <div class="message_action_btn w-50 mx-auto">
                            <button mat-button class="f_16 bg_green text-white mt-3 mt-sm-2 p-2 w-100" (click)="viewProfileCompany(userSelectedinv?.user._id)">{{'See Profile' | translate}}</button>
                            <p class="mb-4" *ngIf="userSelectedinv?.status!=='pending' || userSelectedinv?.sendBy=='company'"></p>
                            <button mat-button class="f_16 bg_green mt-3 mt-sm-2 p-2 w-100 send_btn message_show2" (click)="engageInconversation(userSelectedinv?._id)" *ngIf="userSelectedinv.status=='pending' && userSelectedinv?.sendBy=='user'">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="16.084" height="16.084" viewBox="0 0 16.084 16.084">
                                                <path id="Icon_feather-message-square" data-name="Icon feather-message-square" d="M20.584,15.223A1.787,1.787,0,0,1,18.8,17.01H8.074L4.5,20.584V6.287A1.787,1.787,0,0,1,6.287,4.5H18.8a1.787,1.787,0,0,1,1.787,1.787Z" transform="translate(-4.5 -4.5)" fill="#464646"/>
                                            </svg>
                                        </span>
                                        {{'Engage in conversation' | translate}}</button>
                            <button mat-button class="f_16 bg_green text-white mt-3 mt-sm-2 mb-4 p-2 w-100 decline_btn" *ngIf="userSelectedinv.status=='pending' && userSelectedinv?.sendBy=='user'" (click)="declineconversation(userSelectedinv?._id)">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="17.042" height="17.042" viewBox="0 0 17.042 17.042">
                                                <path id="disabled" d="M8.521,0a8.521,8.521,0,1,0,8.521,8.521A8.531,8.531,0,0,0,8.521,0ZM2.13,8.521a6.349,6.349,0,0,1,1.187-3.7l8.9,8.9A6.381,6.381,0,0,1,2.13,8.521Zm11.594,3.7-8.9-8.9a6.381,6.381,0,0,1,8.9,8.9Z" fill="#fff"/>
                                              </svg>
                                        </span>
                                        {{'Decline' | translate}}</button>

                        </div>
                    </div>




                </div>
            </div>

            <div class="col-sm-7 d-flex" *ngIf="!isOutgoingSelected && selectedTabIndex==1">
                <div class="messages_left_side chatbox_show no-chat-bax text-center">
                    <h2>{{'Invitations' | translate}}</h2>
                    <p>{{'Here we find the list of all invitations to which you have been invited' | translate}}</p>
                    <img src="assets/images/invitations.png">


                </div>
            </div>


            <!-- third tab -->

            <div class="col-sm-7" *ngIf="iscompletedSelected && selectedTabIndex==2">
                <div class="messages_left_side chatbox_show" [ngClass]="{'mobile-show': chatBox}">
                    <div class="media media-single px-3 pt-3 pb-2 align-items-center">

                        <span class="mr-3 btn-back" (click)="goBack()"><svg height="18.136" viewBox="0 0 11.058 18.136" width="11.058" xmlns="http://www.w3.org/2000/svg"><path  d="M14.584,15.26,21.9,8.4a1.234,1.234,0,0,0,0-1.83,1.455,1.455,0,0,0-1.958,0l-8.292,7.769a1.236,1.236,0,0,0-.04,1.787l8.327,7.823a1.457,1.457,0,0,0,1.958,0,1.234,1.234,0,0,0,0-1.83Z" data-name="Icon ionic-ios-arrow-back" fill="#212121" id="Icon_ionic-ios-arrow-back" transform="translate(-11.251 -6.194)"></path></svg></span>
                        <a>
                            <img class="avatar avatar-lg img-fluid" [src]="baseImgUrlCust+ userSelectedinvcomp?.user?.profilePic" alt="..." *ngIf="userSelectedinvcomp?.user?.profilePic" />
                            <img class="avatar avatar-lg img-fluid" src="assets/images/noimageavailable.png" alt="..." *ngIf="!userSelectedinvcomp?.user?.profilePic" />
                        </a>

                        <div class="media-body ml-3 d-flex justify-content-between">
                            <h6>
                                <a class="f_16 fw_medium text_black"> {{ userSelectedinvcomp?.user?.fullName}} - {{userSelectedinvcomp?.assignment?.headline}}</a>
                                <!-- <span class="time-meta pull-right">18:18</span> -->
                                <!-- <p class="f_14 text_gray mt-2">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10.706" height="15.295" viewBox="0 0 10.706 15.295">
                                                <path
                                                    id="placeholder"
                                                    d="M12.853,3A5.349,5.349,0,0,0,7.5,8.353c0,4.015,5.353,9.941,5.353,9.941s5.353-5.927,5.353-9.941A5.349,5.349,0,0,0,12.853,3Zm0,7.265a1.912,1.912,0,1,1,1.912-1.912A1.913,1.913,0,0,1,12.853,10.265Z"
                                                    transform="translate(-7.5 -3)"
                                                    fill="#a8a8a8"
                                                />
                                            </svg>
                                        </span>
                                        Majorstua, 1.0 Miles
                                    </p> -->
                            </h6>
                            <span><img src="assets/images/video-call.png" alt="" class="img-fluid cursor-pointer d-none" /> </span>
                        </div>
                    </div>

                    <div class="messages_show2" id="conversation">
                        <p class="f_16 text-black p-3"><br><br> {{userSelectedinvcomp?.assignment?.description}}
                            <br>
                            <br> {{'Please let me know if you are interested in helping me out.' | translate}}
                            <br>
                            <br> {{'Best Regards.' | translate}}
                            <br> {{ userSelectedinvcomp?.user?.fullName}}
                        </p>
                        <div class="message_action_btn w-50 mx-auto">
                            <button mat-button class="f_16 bg_green text-white mt-3 mt-sm-2 p-2 w-100 mb-4" (click)="viewProfileCompanycomp(userSelectedinvcomp?.user._id)" >{{'See Profile' | translate}}</button>
                            <!-- <p class="mb-4" *ngIf="userSelectedinv?.status!=='pending' || userSelectedinv?.sendBy=='company'"></p>
                                <button mat-button class="f_16 bg_green mt-3 mt-sm-2 p-2 w-100 send_btn message_show2" (click)="engageInconversation(userSelectedinv?._id)" *ngIf="userSelectedinv.status=='pending' && userSelectedinv?.sendBy=='user'">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="16.084" height="16.084" viewBox="0 0 16.084 16.084">
                                                    <path id="Icon_feather-message-square" data-name="Icon feather-message-square" d="M20.584,15.223A1.787,1.787,0,0,1,18.8,17.01H8.074L4.5,20.584V6.287A1.787,1.787,0,0,1,6.287,4.5H18.8a1.787,1.787,0,0,1,1.787,1.787Z" transform="translate(-4.5 -4.5)" fill="#464646"/>
                                                </svg>
                                            </span>
                                            Engage in conversation</button>
                                <button mat-button class="f_16 bg_green text-white mt-3 mt-sm-2 mb-4 p-2 w-100 decline_btn" *ngIf="userSelectedinv.status=='pending' && userSelectedinv?.sendBy=='user'" (click)="declineconversation(userSelectedinv?._id)">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="17.042" height="17.042" viewBox="0 0 17.042 17.042">
                                                    <path id="disabled" d="M8.521,0a8.521,8.521,0,1,0,8.521,8.521A8.531,8.531,0,0,0,8.521,0ZM2.13,8.521a6.349,6.349,0,0,1,1.187-3.7l8.9,8.9A6.381,6.381,0,0,1,2.13,8.521Zm11.594,3.7-8.9-8.9a6.381,6.381,0,0,1,8.9,8.9Z" fill="#fff"/>
                                                  </svg>
                                            </span>
                                            Decline</button> -->

                        </div>
                    </div>




                </div>
            </div>

            <div class="col-sm-7 d-flex" *ngIf="!iscompletedSelected && selectedTabIndex==2">
                <div class="messages_left_side chatbox_show no-chat-bax text-center noimg">
                    <h2>{{'Completed' | translate}}</h2>
                    <p>{{'Here you will find the list of all completed assignments on Hjelpsom.app and reviews that the customer has given on delivered assignments' | translate}}</p>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="sidemenu_fixed">
    <span class="" routerLink="/company/homepagecompany">
        <svg xmlns="http://www.w3.org/2000/svg" width="30.298" height="33.331" viewBox="0 0 30.298 33.331">
            <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(1.5 1.5)">
              <path id="Path_28365" data-name="Path 28365" d="M4.5,13.616,18.149,3,31.8,13.616V30.3a3.033,3.033,0,0,1-3.033,3.033H7.533A3.033,3.033,0,0,1,4.5,30.3Z" transform="translate(-4.5 -3)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
              <path id="Path_28366" data-name="Path 28366" d="M13.5,33.165V18h9.1V33.165" transform="translate(-4.401 -2.835)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            </g>
        </svg>
    </span>

    <!-- <span class="fixed_active messageunread unread" [ngClass]="{'messageunread' : messageUnseenVal>0}"> -->
    <span class="fixed_active" [ngClass]="{'messageunread' : messageUnseenVal>0}">

        <svg xmlns="http://www.w3.org/2000/svg" width="32.161" height="32.161" viewBox="0 0 32.161 32.161">
            <path id="Icon_feather-message-square" data-name="Icon feather-message-square" d="M33.661,23.941a3.24,3.24,0,0,1-3.24,3.24H10.98L4.5,33.661V7.74A3.24,3.24,0,0,1,7.74,4.5H30.421a3.24,3.24,0,0,1,3.24,3.24Z" transform="translate(-3 -3)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
          </svg>
    </span>

    <span class="" routerLink="/company/profilecompany">
        <svg xmlns="http://www.w3.org/2000/svg" width="28.992" height="32.242" viewBox="0 0 28.992 32.242">
            <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(1.5 1.5)">
              <path id="Path_28367" data-name="Path 28367" d="M31.992,32.247V29a6.5,6.5,0,0,0-6.5-6.5h-13A6.5,6.5,0,0,0,6,29v3.249" transform="translate(-6 -3.006)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
              <path id="Path_28368" data-name="Path 28368" d="M25,11a6.5,6.5,0,1,1-6.5-6.5A6.5,6.5,0,0,1,25,11Z" transform="translate(-5.502 -4.5)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            </g>
          </svg>
    </span>
</section>

<app-footercompany class=""></app-footercompany>



<div class="modal fade custom_modal custom_modal_width" id="exampleModalCenter_view_profile" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header position-relative px-2 px-sm-3">
                <h5 class="modal-title text-center f_22 fw_medium w-100" id="exampleModalLongTitle">{{'Profile Details' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body px-2 px-sm-3">

                <div class="d-flex justify-content-center justify-content-sm-between align-items-start">
                    <div class="d-sm-flex px-1 px-sm-3 w-100">
                        <div class="user_profile">
                            <img class="avatar avatar-lg img-fluid" src="assets/images/noimageavailable.png" alt="..." *ngIf="!companyProfile?.profilePic" />
                            <img class="avatar avatar-lg img-fluid" [src]="baseImgUrlComp+companyProfile.profilePic" alt="..." *ngIf="companyProfile?.profilePic" />
                        </div>
                        <div class="user_info pl-0 pl-sm-3">
                            <h5 class="f_22 fw_medium text_black mb-4 mb-sm-3 mt-3 mt-sm-0 text-center text-sm-left">{{companyProfile?.fullName}}</h5>
                            <div>
                                <span>{{'Email' | translate}}</span>
                                <p class="f_16 fw_medium text_black mb-2">{{companyProfile?.email}}</p>
                            </div>
                            <div>
                                <span>{{'Phone number' | translate}}</span>
                                <p class="f_16 fw_medium text_black">{{companyProfile?.countryCode}} {{companyProfile?.phone | formatphonenew}}</p>
                            </div>
                        </div>


                    </div>

                    <div class="">
                        <!-- <button mat-button class="f_16 bg_green text-white mt-4 p-2 px-3" (click)="inviteToAssignment(companyProfile._id)" >Invite To An Assignment</button> -->
                    </div>
                </div>
                <h5 class="f_22 fw_medium text_black mb-4 mb-sm-3 mt-4 pt-2 mt-sm-0 text-center">{{'Active Assignments' | translate}}</h5>
                <div class="active-assignments pl-3 mt-4">

                    <!-- <div class="f_16 fw_medium text_black d-flex align-items-center mb-4" *ngFor="let active_assignment of companyProfile?.activeAssignment | paginate: { itemsPerPage: 5, currentPage: p }"> -->
                    <div class="f_16 fw_medium text_black d-flex align-items-center mb-4" *ngFor="let active_assignment of companyProfile?.activeAssignment">

                        <div class="img-wrap " *ngIf="active_assignment.medias.length<1">
                            <img src="assets/images/noimageavailable.png " alt=" " class="img-fluid " />
                        </div>

                        <ng-container *ngIf="active_assignment?.medias?.length==1">

                            <div class="img-wrap " *ngFor="let img of active_assignment?.medias ">
                                <img [src]="baseImgUrl+img.media " alt=" " width="100% ">
                            </div>

                        </ng-container>

                        <ng-container class="mb-4" *ngIf="active_assignment?.medias?.length>1">
                            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" *ngIf="active_assignment?.medias?.length>0">
                                <div ngxSlickItem *ngFor="let img of  active_assignment?.medias" class="slide">
                                    <div class="img-wrap">
                                        <img [src]="baseImgUrl+img.media" alt="" width="100%">
                                    </div>
                                </div>
                            </ngx-slick-carousel>
                        </ng-container>
                        <p class="mb-0 pl-3 f_16 fw_medium text_black">{{active_assignment?.headline}}</p>

                    </div>
                    <!-- <div class="d-flex justify-content-end my-4">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>

                    </div> -->

                </div>

                <!-- <div class="row mt-4 px-1 px-sm-3">
                    <div class="col-sm-6">
                        <div class="user_reviews">
                            <h6 class="f_18 fw_medium position-relative mb-4">Reviews</h6>

                            <ng-container *ngIf="companyProfile?.allReviews?.length<1">
                                <div class="d-flex">
                                    <div class="uploaded_image_size position-relative">
                                        <img src="assets/images/nodataavailable.jpeg" alt="" class="img-fluid w-100 added_image" />
                                    </div>
                                </div>
                            </ng-container>
                            <div>
                                <ng-container *ngIf="companyProfile?.allReviews?.length==1">
                                    <div class="user_review_box" *ngFor="let img of companyProfile?.allReviews">
                                        <div class="user_rating_img mb-3">
                                            <img class="avatar avatar-lg img-fluid" src="assets/images/review-image.png" alt="..." *ngIf="!img?.review?.author?.profilePic" />
                                            <img class="avatar avatar-lg img-fluid" [src]="baseImgUrlComp+img.review.author.profilePic" alt="..." *ngIf="img?.review?.author?.profilePic" />
                                        </div>
                                        <h5 class="f_16 fw_medium text-black mb-1">{{img?.review?.author?.fullName}}</h5>
                                        <div class="ratings mb-1">
                                            <star-rating value="{{img?.review?.starCount}}" [totalstars]="5" checkedcolor="#ffa749" uncheckedcolor="black" readonly="true" size="24px" (rate)="onRate($event)"></star-rating>
                                        </div>
                                        <p class="mb-0">{{img?.review?.text}}</p>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="companyProfile?.allReviews?.length>1">
                                    <ngx-slick-carousel (init)="slickInit($event)" class="carousel" #slickModala="slick-carousel" [config]="slideConfig2">
                                        <ng-container *ngIf="showcarsuel">
                                            <div ngxSlickItem *ngFor="let img of companyProfile?.allReviews" class="slide">
                                                <div class="user_review_box">
                                                    <div class="user_rating_img mb-3">
                                                        <img class="avatar avatar-lg img-fluid" src="assets/images/review-image.png" alt="..." *ngIf="!img?.review?.author?.profilePic" />
                                                        <img class="avatar avatar-lg img-fluid" [src]="baseImgUrlComp+img.review.author.profilePic" alt="..." *ngIf="img?.review?.author?.profilePic" />
                                                    </div>
                                                    <h5 class="f_16 fw_medium text-black mb-1">{{img?.review?.author?.fullName}}</h5>
                                                    <div class="ratings mb-1">
                                                        <star-rating value="{{img?.review?.starCount}}" [totalstars]="5" checkedcolor="#ffa749" uncheckedcolor="black" readonly="true" size="24px" (rate)="onRate($event)"></star-rating>
                                                    </div>
                                                    <p class="mb-0">{{img?.review?.text}}</p>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ngx-slick-carousel>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="user_reviews">
                            <h6 class="f_18 fw_medium position-relative mb-4">Uploaded Images</h6>
                            <div class="row">
                                <ng-container *ngIf="companyProfile?.galleryImages?.length>0">
                                    <div class="col-6 col-sm-4 mb-4 mb-sm-3" *ngFor="let images of companyProfile?.galleryImages">
                                        <div class="d-flex">
                                            <div class="uploaded_image_size position-relative">
                                                <img [src]="baseImgUrl+images" alt="" class="img-fluid w-100 added_image" />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="companyProfile?.galleryImages?.length<1">
                                    <div class="col-6 col-sm-4 mb-4 mb-sm-3">
                                        <div class="d-flex">
                                            <div class="uploaded_image_size position-relative">
                                                <img src="assets/images/noimageavailable.png" alt="" class="img-fluid w-100 added_image" />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="modal fade custom_modal" id="translate_message" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm select_language" role="document">
        <div class="modal-content">
            <div class="modal-header position-relative px-2 px-sm-3 pb-0 pt-1">
                <h5 class="modal-title text-left f_20  fw_medium w-100" id="exampleModalLongTitle">{{'Select Language' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body px-2 px-sm-3">
                <div class="custom-control custom-radio mb-2 pb-1">
                    <input type="radio" id="English" name="selectlanguage" value="en" class="custom-control-input" [(ngModel)]="languageSelected">
                    <label class="custom-control-label" for="English">{{'English' | translate}}</label>
                </div>
                <div class="custom-control custom-radio mb-2 pb-1">
                    <input type="radio" id="Polish" name="selectlanguage" value="pl" class="custom-control-input" [(ngModel)]="languageSelected">
                    <label class="custom-control-label" for="Polish">{{'Polish' | translate}}</label>
                </div>

                <div class="custom-control custom-radio mb-2 pb-1">
                    <input type="radio" id="Bulgarian" name="selectlanguage" value="bg" class="custom-control-input" [(ngModel)]="languageSelected">
                    <label class="custom-control-label" for="Bulgarian">{{'Bulgarian' | translate}}</label>
                </div>

                <div class="custom-control custom-radio mb-2 pb-1">
                    <input type="radio" id="Lithuanian" name="selectlanguage" value="lt" class="custom-control-input" [(ngModel)]="languageSelected">
                    <label class="custom-control-label" for="Lithuanian">{{'Lithuanian' | translate}}</label>
                </div>

                <div class="custom-control custom-radio mb-2 pb-1">
                    <input type="radio" id="Norway" name="selectlanguage" value="no" class="custom-control-input" [(ngModel)]="languageSelected">
                    <label class="custom-control-label" for="Norway">{{'Norwegian' | translate}}</label>
                </div>

                <div class="">
                    <button mat-button class="f_16 bg_green text-white mt-4 py-0 px-3 mx-auto d-block" (click)="translateText()">{{'Translate' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div *ngIf="isLoading">
    <div class="spinner_overlay"></div>
    <div class="loader-container">
        <div class="loader dots-wave">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
    </div>
    </div> -->
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-footercompany',
  templateUrl: './footercompany.component.html',
  styleUrls: ['./footercompany.component.scss']
})
export class FootercompanyComponent implements OnInit {
  languageSelected: string;

  constructor(private translateService:TranslateService, private authenticationService: AuthenticationService
    ) { }

  ngOnInit(): void {
    // this.languageSelected = localStorage.getItem('selectLanguage');
    // this.translateService.use(this.languageSelected)

    this.getTranslation();

  }

  getTranslation(){
    this.authenticationService.getComapnyTranslation().subscribe((res:any)=>{
      this.translateService.use(res.language);
   })
  }

}

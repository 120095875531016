<app-header-customer class="d-none d-sm-block"></app-header-customer>

<header class="splash_top_bar d-block d-sm-none">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <nav class="navbar navbar-expand-lg navbar-dark bg-none flex-sm-row p-0 p-sm-2 justify-content-between">
                    <span routerLink="/customers/homepagecustomer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="11.729" height="20.516" viewBox="0 0 11.729 20.516">
                            <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M14.786,16.449l7.763-7.757a1.466,1.466,0,0,0-2.077-2.07l-8.795,8.789a1.463,1.463,0,0,0-.043,2.022l8.831,8.85a1.466,1.466,0,1,0,2.077-2.07Z" transform="translate(-11.251 -6.194)" fill="#fff"/>
                          </svg>
                    </span>
                    <a class="navbar-brand" href="#">
                        <img src="assets/images/logo_white.svg" alt="logo" class="img-fluid" />
                    </a>

                    <span class="d-flex align-items-center p-0">
                        
                    </span>
                </nav>
            </div>
        </div>
    </div>
</header>

<section class="my-5">
    <div class="container">
        <div class="row">
            <div class="col-sm-11 mx-auto">
                <div class="profile_section">
                    <h1 class="text-center mb-4 d-none d-sm-block">
                        {{'My Profile' | translate}}
                        <span></span>
                        <span></span>
                    </h1>
                    <div class="mb-4">
                        <button mat-button class="bg_green f_14 edit_profile_btn fw_medium d-block d-sm-none ml-auto mt-2 mr-2" routerLink="/customers/editprofilecustomer">{{'Edit Profile' | translate}}</button>
                    </div>
                    <div class="d-flex justify-content-center justify-content-sm-between align-items-start">
                        <div class="d-sm-flex px-3 px-sm-0">
                            <div class="user_profile">

                                <img class="avatar avatar-lg img-fluid" src="assets/images/noimageavailable.png" *ngIf="profilePic && profilePic=='https://api-dev.hjelpsom.app/static/'" alt="..." />
                                <img class="avatar avatar-lg img-fluid" src="assets/images/noimageavailable.png" *ngIf="!profilePic " alt="..." />
                                <img class="avatar avatar-lg img-fluid" src="{{profilePic}}" *ngIf="profilePic && profilePic!=='https://api-dev.hjelpsom.app/static/'" alt="..." />
                            </div>
                            <div class="user_info pl-0 pl-sm-3">
                                <h5 class="f_22 fw_medium text_black mb-4 mb-sm-3 mt-3 mt-sm-0 text-center text-sm-left">{{userDetails?.fullName}}</h5>
                                <div class="d-flex align-items-center">
                                    <span class="profile_icon_bg d-flex d-sm-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.733" height="15.451" viewBox="0 0 16.733 15.451">
                                            <path id="Path_3847" data-name="Path 3847" d="M16.692,16.4a1.2,1.2,0,0,1-.136.335,9.872,9.872,0,0,1-1.718,2.1,5.7,5.7,0,0,1-.627.438l-.5.032c-.243-.032-.487-.054-.728-.1a9.942,9.942,0,0,1-3.049-1.171A26.3,26.3,0,0,1,5.47,14.762a26.027,26.027,0,0,1-3.479-3.627A10.624,10.624,0,0,1,.266,8.106C.172,7.83.109,7.544.032,7.269L0,6.767A4.358,4.358,0,0,1,1.091,5.174c.335-.345.678-.669,1.031-.991a1.228,1.228,0,0,1,1.094-.311.863.863,0,0,1,.562.335C4.5,5.161,5.209,6.121,5.953,7.06A1.273,1.273,0,0,1,6.137,8.4c-.09.239-.209.468-.32.7-.127.269-.253.54-.392.8a.192.192,0,0,0,.03.261c.5.514.98,1.051,1.506,1.544a23.516,23.516,0,0,0,3.126,2.553.263.263,0,0,0,.345,0c.407-.278.82-.544,1.238-.8a1.412,1.412,0,0,1,1-.248.924.924,0,0,1,.308.107q1.658.945,3.313,1.9A1.051,1.051,0,0,1,16.692,16.4Z" transform="translate(0 -3.847)" fill="#ff3e3e"/>
                                          </svg>
                                    </span>
                                    <div class="ml-3 ml-sm-0">
                                        <span>{{'Phone Number' | translate}}</span>
                                        <p class="f_16 fw_medium text_black mb-2">{{userDetails?.countryCode}} {{userDetails?.phone | formatphonenew}}</p>
                                    </div>

                                </div>
                                <div class="d-flex align-items-center">
                                    <span class="profile_icon_bg d-flex d-sm-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.287" height="13.83" viewBox="0 0 17.287 13.83">
                                            <path id="Email" d="M18.559,6H4.729a1.726,1.726,0,0,0-1.72,1.729L3,18.1A1.734,1.734,0,0,0,4.729,19.83h13.83A1.734,1.734,0,0,0,20.287,18.1V7.729A1.734,1.734,0,0,0,18.559,6Zm0,3.457-6.915,4.322L4.729,9.457V7.729l6.915,4.322,6.915-4.322Z" transform="translate(-3 -6)" fill="#3773ff"/>
                                          </svg>
                                    </span>
                                    <div class="ml-3 ml-sm-0">
                                        <span>{{'Email Address' | translate}}</span>
                                        <p class="f_16 fw_medium text_black">{{userDetails?.email}}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <button mat-button class="bg_green f_14 edit_profile_btn fw_medium d-none d-sm-block" routerLink="/customers/editprofilecustomer">{{'Edit Profile' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="sidemenu_fixed" routerLink="/customers/homepagecustomer">
    <span class="">
            <svg xmlns="http://www.w3.org/2000/svg" width="30.298" height="33.331" viewBox="0 0 30.298 33.331">
                <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(1.5 1.5)">
                  <path id="Path_28365" data-name="Path 28365" d="M4.5,13.616,18.149,3,31.8,13.616V30.3a3.033,3.033,0,0,1-3.033,3.033H7.533A3.033,3.033,0,0,1,4.5,30.3Z" transform="translate(-4.5 -3)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  <path id="Path_28366" data-name="Path 28366" d="M13.5,33.165V18h9.1V33.165" transform="translate(-4.401 -2.835)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                </g>
            </svg>
        </span>

    <span class="" routerLink="/customers/uploadassignmentcustomer">
            <svg xmlns="http://www.w3.org/2000/svg" width="32.29" height="32.29" viewBox="0 0 32.29 32.29">
                <g id="Icon_feather-upload" data-name="Icon feather-upload" transform="translate(1.5 1.5)">
                  <path id="Path_31593" data-name="Path 31593" d="M33.79,22.5v6.509a3.254,3.254,0,0,1-3.254,3.254H7.754A3.254,3.254,0,0,1,4.5,29.009V22.5" transform="translate(-4.5 -2.973)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  <path id="Path_31594" data-name="Path 31594" d="M26.772,12.636,18.636,4.5,10.5,12.636" transform="translate(-3.991 -4.5)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  <path id="Path_31595" data-name="Path 31595" d="M18,4.5V24.027" transform="translate(-3.355 -4.5)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                </g>
              </svg>
        </span>

    <span class="" routerLink="/customers/messagecustomer" [ngClass]="{'messageunread' : messageUnseenVal>0}">
            <svg xmlns="http://www.w3.org/2000/svg" width="32.161" height="32.161" viewBox="0 0 32.161 32.161">
                <path id="Icon_feather-message-square" data-name="Icon feather-message-square" d="M33.661,23.941a3.24,3.24,0,0,1-3.24,3.24H10.98L4.5,33.661V7.74A3.24,3.24,0,0,1,7.74,4.5H30.421a3.24,3.24,0,0,1,3.24,3.24Z" transform="translate(-3 -3)" fill="none" stroke="#1bbc9b" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
              </svg>
        </span>
</section>

<app-footercustomer class="d-none d-sm-block footer_fixed"></app-footercustomer>

<div *ngIf="isLoading">
    <div class="spinner_overlay"></div>
    <div class="loader-container">
        <div class="loader dots-wave">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
        </div>
    </div>
</div>